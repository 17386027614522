import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _Booking = {
  show: async ({ id, setLoading }) => {
    return _axios
      .get(
        `${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/show/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  },

  index: async ({ id }) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  },

  accept: async ({ id }) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/accept/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  },
  confirm: async ({ id }) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/confirm/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  },

  post: (data, id) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/booking/store/${id}`,
        data
      )
      .then((res) => res.data),

  VerIfyPassenger: (data, setLoading, id) =>
    _axios
      .post(
        `${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/verify-passenger/${id}`,
        data
      )
      .then((res) => {
        setLoading(true);
        return res;
      }),
  VerIfyClient: (data, setLoading, id) =>
    _axios
      .post(
        `${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/verify-client/${id}`,
        data
      )
      .then((res) => {
        setLoading(true);
        return res;
      }),

  Reject: (data, setLoading, id) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/reject/${id}`,
        data
      )
      .then((res) => {
        setLoading(true);
      }),

  delete: (id) =>
    _axios
      .delete(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/` + id
      )
      .then((res) => res.data),

  update: ({ editedID, formData, loading, setLoading, setOpen }) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/bookings/` + editedID,
        formData
      )
      .then((res) => {
        setLoading(false);
        setOpen(false);
      }),
};
