import { useTheme } from "@mui/material";

import logoRlink from "assets/images/LogoArabic.png";

import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RenderLogo = () => {
  const theme = useTheme();
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const role = _AuthApi.getRole();

  const isDarkEn = theme.palette.mode === "dark" && theme.direction !== "rtl";
  const isDarkAr = theme.palette.mode === "dark" && theme.direction === "rtl";
  const isLightAr = theme.palette.mode === "light" && theme.direction === "rtl";
  const isLightEn = theme.palette.mode === "light" && theme.direction !== "rtl";

  if (isDarkEn)
    return (
      <div
        onClick={() => navigate(`/dashboard/${role}`)}
        style={{
          backgroundImage: `url(${logoRlink})`,
          width: "100%",
          height: "100%",
          margin: "10px",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      ></div>
    );
  else if (isDarkAr)
    return (
      <div
        onClick={() => navigate(`/dashboard/${role}`)}
        style={{
          backgroundImage: `url(${logoRlink})`,
          width: "100%",
          height: "100%",
          margin: "10px",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      ></div>
    );
  else if (isLightAr)
    return (
      <div
        onClick={() => navigate(`/dashboard/${role}`)}
        style={{
          backgroundImage: `url(${logoRlink})`,
          width: "100%",
          height: "100%",
          margin: "10px",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      ></div>
    );
  else if (isLightEn)
    return (
      <div
        onClick={() => navigate(`/dashboard/${role}`)}
        style={{
          backgroundImage: `url(${logoRlink})`,
          width: "100%",
          height: "100%",
          margin: "10px",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      ></div>
    );

  return <>{t("Logo")}</>;
};

export default RenderLogo;
