
import React from "react";
import { Route, Routes } from "react-router-dom";
import EmployeeComponent from "./EmployeeComponent";
import EmployeeIndex from "./pages/EmployeeIndex";
import EmployeeUpdate from "./pages/EmployeeUpdate";
import EmployeeView from "./pages/EmployeeView";
import EmployeeCreate from "./pages/EmployeeCreate";

const EmployeeRouting = () => {
  return (
    <Routes>
      <Route element={<EmployeeComponent />}>
        <Route path="/" element={<EmployeeIndex />} />
        <Route path="/update/:id" element={<EmployeeUpdate />} />
        <Route path="/view/:id" element={<EmployeeView />} />
        <Route path="/create" element={<EmployeeCreate />} />
      </Route>
      <Route path="*" element={<p>not found 404</p>} />
    </Routes>
  );
};

export default EmployeeRouting;
