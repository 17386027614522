import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _Trip = {
  index: async ({ query, searchTo, page, limit = 9, companySearch }) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/trip?page=${page}&count=${limit}${
          query !== "" ? `&search_from=${query}` : ""
        }${searchTo !== "" ? `&search_to=${searchTo}` : ""}${
          companySearch !== "" ? `&search=${companySearch}` : ""
        }`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },

  getActive: async (id) => {
    if (localStorage.getItem("role") === "admin") {
      if (id) {
        return _axios
          .get(`/admin/company/trips/${id}`, {
            headers: { ...HttpRequestInterceptor() },
          })
          .then((res) => res.data);
      }
    } else
      return _axios
        .get(`company/trip/get-active`, {
          headers: { ...HttpRequestInterceptor() },
        })
        .then((res) => res.data);
  },

  post: (data, setLoading) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/trip`,
        data
      )
      .then((res) => {
        setLoading(true);
        return res.data;
      }),

  view: async (id) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/trip/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },
  delete: (id) =>
    _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/trip/delete/` + id
      )
      .then((res) => res.data),
  stopBooking: (id, role) =>
    _axios.get(`/${role}/trip/end/` + id).then((res) => res.data),

  update: ({ editedID, formData, loading, setLoading, setOpen }) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/trip/` + editedID,
        formData
      )
      .then((res) => res),
};
