import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { useTheme } from "@emotion/react";
import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { BoxStyled } from "components/styled/BoxStyled";
import { useBooking } from "hooks/booking/useBooking";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import AcciptDialog from "../components/Dialog";
import RejectDialog from "../components/RejectDialog";
import ConfirmDialog from "../components/confirmDialog";

const BookingIndex = () => {
  const { t } = useTranslation("index");
  const params = useParams();
  const theme = useTheme();

  const { data, page, setPage, isLoading, count } = useBooking(params.id);

  const navigate = useNavigate();

  const [direction] = settingsStore((state) => [state.direction]);

  const columns = useMemo(() => {
    return [
      t("client name"),
      t("trip number"),
      t("pay later"),
      t("trip departure time"),
      t("invoice"),
      t("passenger count"),
      t("created at"),
      t("status"),
      t("operations"),
    ];
  }, [t]);

  const handleView = useCallback(
    (id) => {
      navigate(`view/${id}`);
    },
    [navigate]
  );

  const handleCreate = () => navigate("create");

  const getStatusColor = (status) => {
    let background;
    switch (status) {
      case "verified":
        background = "#dfb57e";
        break;
      case "rejected":
        background = "#e98484";
        break;
      case "pending":
        background = "#72b5ec";
        break;
      case "done":
        background = "#74b461";
        break;
      case "expired":
        background = "#ccc92f";
        break;
      default:
        background = "#bebebe";
    }
    return background;
  };

  const rows = useMemo(() => {
    return data?.bookings?.map((booking, id) => (
      <TableRow sx={{ height: "65px" }} key={booking.id}>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.client_name === " "
            ? "Office Booking"
            : booking?.client_name}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.trip_number ?? "Null"}
        </TableCell>
        <TableCell sx={{ width: 20 }}>
          {booking?.pay_later === 1 ? (
            <Chip label={"yes"} color="primary" variant="outlined" />
          ) : (
            <Chip label={"no"} color="info" variant="outlined" />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.trip_departure_time ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.invoice ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.passenger_count ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {booking?.created_at ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 120 }} align="center">
          <Typography
            sx={{
              borderRadius: "50px",
              p: 1,
              background: getStatusColor(booking.status),
            }}
          >
            {booking.status}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            minWidth: 50,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* //*View */}
          <IconButton onClick={() => handleView(booking.id)}>
            <Tooltip title={direction === "ltr" ? "View" : "مشاهدة"}>
              <lord-icon
                src="https://cdn.lordicon.com/fcrbbplx.json"
                trigger="morph"
                colors={`primary:${theme.palette.primary.main}`}
                style={{ width: 25, height: 25 }}
              ></lord-icon>
            </Tooltip>
          </IconButton>

          {/* //*confirm */}
          {booking.status === "temporarliy" && booking.pay_later === 1 && (
            <IconButton>
              <ConfirmDialog id={booking?.id} count={count} page={page} />
            </IconButton>
          )}

          {/* //*accept */}
          {booking.status === "pending" && (
            <IconButton>
              <AcciptDialog id={booking?.id} count={count} page={page} />
            </IconButton>
          )}

          {/*//* Reject */}
          {booking.status === "pending" && (
            <IconButton>
              <RejectDialog id={booking?.id} count={count} page={page} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    ));
  }, [data, count, direction, handleView, page, theme.palette.primary.main]);

  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("booking")}
          </Typography>
          <Button
            startIcon={<AddIcon />}
            sx={{
              color: "primary.main",
              backgroundColor: "origin.main",
              "&:hover": { backgroundColor: "origin.main" },
            }}
            onClick={handleCreate}
          >
            {t("New booking")}
          </Button>
        </Box>

        {data && (
          <BoxStyled sx={{ px: "10px" }}>
            {isLoading ?? <CircularProgress />}
            {data?.bookings.length > 0 ? (
              <Table
                columns={columns}
                rows={rows}
                page={page}
                setPage={setPage}
                count={Math.ceil(data?.pagination?.total / count)}
              />
            ) : (
              <Typography
                sx={{
                  color: "text.main",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
                variant="h5"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/nocovwne.json"
                  trigger="morph"
                  colors={`primary:#cee7f1,secondary:'#84959c'`}
                  style={{ width: 100, height: 100, margin: "0px 5px" }}
                ></lord-icon>
                {t("No Booking on This Trip")}
              </Typography>
            )}
          </BoxStyled>
        )}
      </Box>
    </>
  );
};

export default BookingIndex;
