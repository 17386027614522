import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardComponent from "./DashboardComponent";
import DashboardIndex from "./pages/DashboardIndex";
import ProfileRouting from "modules/profile/ProfileRouting";
import BusRouting from "modules/bus/BusRouting";
import TripRouting from "modules/trip/TripRouting";
import EmployeeRouting from "modules/companyModules/employee/EmployeeRouting";
import BookingRouting from "modules/companyModules/booking/BookingRouting";
import NotFound from "modules/notFound/404";
import ReviewRouting from "modules/review/ReviewRouting";
import CustomUsersNotifiRouting from "modules/CustomUsersNotifi/CustomUsersNotifiRouting";

const CompanyDashboardRouting = () => {
    return (
        <Routes>
            {
                <Route element={<DashboardComponent />}>
                    <Route path="/" element={<DashboardIndex />} />
                    <Route path="/profile/*" element={<ProfileRouting />} />
                    <Route path="/bus/*" element={<BusRouting />} />
                    <Route path="/trip/*" element={<TripRouting />} />
                    <Route path="/employee/*" element={<EmployeeRouting />} />
                    <Route path="/booking/*" element={<BookingRouting />} />
                    <Route path="/review/*" element={<ReviewRouting />} />
                    <Route path="/NotificationCustom/*" element={<CustomUsersNotifiRouting />} />
                </Route>
            }
            <Route path="*" element={<NotFound />} />

        </Routes>
    );
};

export default CompanyDashboardRouting;