
const colors = {
  background: {
    main: "#394855",
    paper: "#455461",
    login: '#3948558f',
    hover: '#394855'
  },
  extrenal: {
    main: "#394855",
  },
  origin: {
    main: "#394855",
  },
  edit: {
    main: "#D0F5FF",
  },
  text: {
    main: "#F2FAFF",
  },
  primary: {
    main: "#6599C0",
  },
  inactive: {
    main: "#455461",
  },
  darkGray: {
    main: "rgb(156 172 189)",
  },
  lightGray: {
    main: "#b4bdcf",
  },
  warning: {
    main: "rgb(231, 191, 70)",
  },
  error: {
    main: "#e96262",
  },
  success: {
    main: "rgb(135, 224, 108)",
  },
  card: {
    main: "#34414d",
  },
};

export const typography = {
  fontFamily: [].join(","),
  button: {
    fontSize: "14px",
  },

  h1: {
    fontSize: "64px",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "48px",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "40px",
    fontWeight: "bold",
  },
  h4: {
    fontSize: 26,
    fontWeight: "bold",
  },
  h5: {
    fontSize: 23,
    fontWeight: "bold",
  },
  h6: {
    fontSize: 20,
  },

  subtitle1: {
    fontSize: "0.955rem",
  },
  subtitle2: {
    fontSize: 12,
  },

  body1: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  body2: {
    fontSize: "14px",
  },
  caption: {
    fontSize: "12px",
  },
  inputTitle: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: colors.text.main
  },
};

export const darkThemeOptions = {
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            "&:hover": {
              backgroundColor: colors.background.hover,
              transition: "250ms",
            },
            color: colors.text.main,
            backgroundColor: "inherit",
            "&.Mui-selected ": {
              color: "#ffff",
              backgroundColor: colors.background.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.text.main,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.primary.main,
          ":hover": {
            backgroundColor: colors.extrenal.main,
            color: colors.origin.paper,
            transform: "scale(1.0.8)",
            transition: "200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },

        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "&.MuiTableHead-root ": {
            backgroundColor: colors.inactive.main,
          },
          ".MuiTableCell-root.MuiTableCell-head": {
            color: colors.text.main,
            textTransform: "capitalize",
          },
          ".MuiTableCell-root.MuiTableCell-head:first-of-type": {
            borderRadius: "5px 0 0 5px",
          },
          ".MuiTableCell-root.MuiTableCell-head:last-of-type": {
            borderRadius: "0 5px 5px 0",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root.MuiTableCell-body": {
            color: colors.text.main,
            ...typography.body2,
            borderBottom: "none",
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(189, 200, 240,0.2)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
        },
      },
    },
  },

  palette: {
    mode: "dark",
    ...colors,
  },
  typography: {
    ...typography,
  },
};
