import { useState } from "react";
import { useQuery } from "react-query";
import { _Company } from "api/company/company";

export const useCompany = (id) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [query, setQuery] = useState("");
  const role = localStorage.getItem("role");

  const { data, isLoading, refetch } = useQuery(
    ["company", page, count, query],
    async () => {
      if (role === "admin") {
        // Call the API only if the role is "admin"
        const result = await _Company.index({
          query,
          page,
          count,
        });
        return result;
      }
      // If the role is not "admin," return an empty object or handle it as needed
      return {};
    }
  );

  return {
    data,
    isLoading,
    page,
    setPage,
    count,
    setCount,
    refetch,
    setQuery,
  };
};
