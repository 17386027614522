//* React 
import React from "react";
//* mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

//* Third party components
import Loader from "components/shared/Loader";
import { TextFieldStyled } from "components/styled/TextField";
import { useVerifyPassenger } from "../hooks/useVerifyPassenger";
import ButtonLoader from "components/shared/ButtonLoader";

const VerifyPassenger = ({ label, id, DefualtData }) => {
    const {
        hanldeCreate,
        register,
        handleSubmit,
        loading,
        t,
        errors,
        details,
        direction,
        open,
        handleClose,
        handleClickOpen,
    } = useVerifyPassenger({ id, DefualtData })

    return (
        <React.Fragment>
            <Button disabled={label === 'not_verified' ? false : true} sx={{ borderRadius: "50px", }} onClick={handleClickOpen}>
                <Tooltip title={direction === "ltr" ? "Verify User" : "تأكيد المستخدم"}>
                    <span style={{ display: 'flex' }}>
                        <lord-icon
                            src="https://cdn.lordicon.com/yyecauzv.json"
                            trigger="morph"
                            colors={`primary:#d46f5d,`}
                            style={{ width: 25, height: 25, mx: '0px 5px' }}
                        ></lord-icon>
                        <Typography>{t(label)}</Typography>
                    </span>
                </Tooltip>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box component="form">
                    <DialogTitle id="alert-dialog-title" sx={{ color: "primary.main" }}>
                        {t("Verify Passenger")}
                    </DialogTitle>

                    <DialogContent>
                        <Grid container >
                            {details.map((item, index) => (
                                <Grid key={index} xs={6} sx={{ p: "10px", mt: '10px' }}>
                                    <Box sx={{ margin: "0 0 8px 5px" }}>
                                        <Typography variant="inputTitle">{item.head}</Typography>
                                    </Box>
                                    <TextFieldStyled
                                        sx={{ width: "100%" }}
                                        type={item.type}
                                        placeholder={item.placeholder}
                                        name={item.name}
                                        {...register(item.register)}
                                        error={errors[item.error]?.message}
                                        helperText={errors[item.helperText]?.message || ""}
                                        defaultValue={item.value}
                                    />
                                </Grid>
                            ))}
                            <Grid xs={6} sx={{ p: "10px", mt: '10px' }}>
                                <Box sx={{ margin: "0 0 8px 5px" }}>
                                    <Typography variant="inputTitle">{t('type')}</Typography>
                                </Box>
                                <Select
                                    fullWidth
                                    {...register('type')}
                                    error={errors?.type?.message}
                                    helperText={errors?.type?.message || ""}
                                    defaultValue={DefualtData?.type}
                                >
                                    <MenuItem value="military">
                                        {t('military')}
                                    </MenuItem>
                                    <MenuItem value="civil">
                                        {t('civil')}
                                    </MenuItem>
                                    <MenuItem value="foreign">
                                        {t('foreign')}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid xs={6} sx={{ p: "10px", mt: '10px' }}>
                                <Box sx={{ margin: "0 0 8px 5px" }}>
                                    <Typography variant="inputTitle">{t('gender')}</Typography>
                                </Box>
                                <Select
                                    fullWidth
                                    {...register('gender')}
                                    error={errors?.gender?.message}
                                    helperText={errors?.gender?.message || ""}
                                    defaultValue={DefualtData?.gender}
                                >
                                    <MenuItem value="male">
                                        <Box sx={{ color: 'text.main' }}>
                                            {t('male')}
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="female">
                                        <Box sx={{ color: 'text.main' }}>
                                            {t('female')}
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('Cancel')}</Button>
                        {loading && <Loader />}
                        <ButtonLoader name={t("Verify")}
                            onClick={() => handleSubmit(hanldeCreate)()}
                            loading={loading}
                            disableOnLoading
                        >
                            {t("Verify")}
                        </ButtonLoader>
                    </DialogActions>
                </Box>
            </Dialog>
        </React.Fragment >
    );
};

export default VerifyPassenger;
