import { _Statistics } from "api/statistics/statistics";
import { useQuery } from "react-query";

export const useStatistics = (id) => {
  const { data, isLoading, refetch } = useQuery(["statistics"], () =>
    _Statistics.index().then((res) => res)
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
