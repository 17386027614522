import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette?.card?.main,
  borderRadius: "16px",
  padding: "24px 0 24px 0",
  boxShadow:
    theme.palette.mode === "dark"
      ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
      : "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
}));
