
import React from "react";
import { Route, Routes } from "react-router-dom";
import BookingComponent from "./BookingComponent";
import BookingIndex from "./pages/BookingIndex";
import BookingView from "./pages/BookingView";
import BookingCreate from "./pages/BookingCreate";

const BookingRouting = () => {
  return (
    <Routes>
      <Route element={<BookingComponent />}>
        <Route path="/:id" element={<BookingIndex />} />
        <Route path=":id/view/:id" element={<BookingView />} />
        <Route path=":id/create" element={<BookingCreate />} />
      </Route>
      <Route path="*" element={<p>not found 404</p>} />
    </Routes>
  );
};

export default BookingRouting;
