import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { _Profile } from "api/profile/profile";
import { TextFieldStyled } from "components/styled/TextField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

let schema = yup.object().shape({
  current_password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  new_password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("new_password")], "your password does not match"),
});
const ChangePassword = () => {
  const { t } = useTranslation("index");
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (input) => {
    _Profile
      .changePass(input)
      .then((res) => {
        if (res.success === true) setOpen(!open);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Dialog open={!open} onClose={() => setOpen(!open)} size="lg">
        <DialogTitle sx={{ color: "text.main" }}>{t("Edit Row")}</DialogTitle>
        <Box component="form">
          <DialogContent>
            <Typography sx={{ color: "text.main" }}>
              {t("Old Password")}
            </Typography>
            <TextFieldStyled
              autoComplete=""
              margin="dense"
              fullWidth
              variant="outlined"
              sx={{
                input: {
                  backgroundColor: "card.main",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "textColor.50",
                    opacity: "0.4",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.text.main,
                    },
                  },
                  color: theme.palette.text.main,
                },
              }}
              type="text"
              {...register("current_password", { validate: true })}
              placeholder={t("Old Password")}
              id="current_password"
              name="current_password"
              defaultValue={formData.current_password}
              onChange={handleChange}
              error={errors?.current_password?.message}
              helperText={errors?.current_password?.message || ""}
            />
          </DialogContent>
          <DialogContent>
            <Typography sx={{ color: "text.main" }}>
              {" "}
              {t("Password")}
            </Typography>
            <TextFieldStyled
              autoComplete=""
              margin="dense"
              fullWidth
              variant="outlined"
              sx={{
                input: {
                  backgroundColor: "card.main",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "textColor.50",
                    opacity: "0.4",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.text.main,
                    },
                  },
                  color: theme.palette.text.main,
                },
              }}
              type="text"
              placeholder={t("Password")}
              {...register("new_password")}
              id="new_password"
              name="new_password"
              defaultValue={formData.new_password}
              onChange={handleChange}
              error={errors?.new_password?.message}
              helperText={errors?.new_password?.message || ""}
            />
          </DialogContent>
          <DialogContent>
            <Typography sx={{ color: "text.main" }}>
              {t("Confirm Password")}
            </Typography>
            <TextFieldStyled
              autoComplete="password"
              margin="dense"
              fullWidth
              variant="outlined"
              sx={{
                input: {
                  backgroundColor: "card.main",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "textColor.50",
                    opacity: "0.4",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.text.main,
                    },
                  },
                  color: theme.palette.text.main,
                },
              }}
              type="text"
              {...register("confirm_password")}
              placeholder={t("Confirm Password")}
              id="confirm_password"
              name="confirm_password"
              defaultValue={formData.confirm_password}
              onChange={handleChange}
              error={errors?.confirm_password?.message}
              helperText={errors?.confirm_password?.message || ""}
            />
          </DialogContent>
        </Box>

        <DialogActions>
          <Button sx={{ color: "text.main" }} onClick={() => setOpen(!open)}>
            {t("Cancel")}
          </Button>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              width: 130,
              alignItems: "center",
              backgroundColor: "origin.main",
              textAlign: "center",
              "&:hover": {
                backgroundColor: "origin.main",
                transform: "scale(1.1)",
              },
            }}
            onClick={() => handleSubmit(onSubmit)()}
          >
            {t("Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePassword;
