import { useQueryClient, useMutation } from "react-query";
import { _Trip } from "api/trip/trip";
import { useSnackbar } from "notistack";

export const useDeleteTrip = ({ page, count }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation((id) => _Trip.delete(id), {
    onMutate: async (id) => {
      await queryClient.cancelQueries(["trip", page, count]);
      const previousData = queryClient.getQueriesData(["trip", page, count]);
      queryClient.setQueryData(["trip", page, count], (oldQueryData) => {
        const oldQueryDataCopy = oldQueryData?.companies.filter(
          (old) => +old.id !== +id
        );
        return oldQueryDataCopy;
      });
      return {
        previousData,
      };
    },
    onSuccess: () => {
      enqueueSnackbar("Deleted", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return queryClient.invalidateQueries(["trip", page, count]);
    },
    onError: (_error, _hero, context) => {
      queryClient.setQueryData(["trip", page, count], context.prevuiosQuery);
    },
  });
};
