import React from "react";
import { Route, Routes } from "react-router-dom";
import TripComponent from "./TripComponent";
import TripIndex from "./pages/TripIndex";
import TripUpdate from "./pages/TripUpdate";
import TripView from "./pages/TripView";
import TripCreate from "./pages/TripCreate";

const TripRouting = () => {
  return (
    <Routes>
      <Route element={<TripComponent />}>
        <Route path="/" element={<TripIndex />} />
        <Route path="/update/:id" element={<TripUpdate />} />
        <Route path="/view/:id" element={<TripView />} />
        <Route path="/create" element={<TripCreate />} />
      </Route>
      <Route path="*" element={<p>not found 404</p>} />
    </Routes>
  );
};

export default TripRouting;
