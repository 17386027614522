import { _axios } from "../../interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
const role = localStorage.getItem('role')

export const _Company = {
  index: async ({ query, page, limit = 10 }) => {
    return _axios
      .get(
        `/admin/company?page=${page}&count=${limit}${query !== "" ? `&search=${query}` : ""
        }`, {
        headers: {
          ...HttpRequestInterceptor()
        }
      }
      )
      .then((res) => res.data);
  },
  getActive: async () => {
    return role === 'admin' ? _axios
      .get(
        `/admin/company/getActive`
      )
      .then((res) => res.data) : null
  },
  post: (data, setLoading) =>
    _axios.post("/admin/company", data).then((res) => {
      setLoading(true)
      return res.data
    }),

  delete: (id) => _axios.delete(`/admin/company/${id}`).then((res) => res.data),

  update: ({ editedID, formData }) =>
    _axios.post(`/admin/company/${editedID}`, formData).then((res) => {
    }),

  changePass: (data, id) => {
    return _axios
      .post(`admin/company/update-password/${id}`, data).then(res => res.data)
  },
};
