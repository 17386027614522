
import React from "react";
import { Route, Routes } from "react-router-dom";
import BusComponent from "./BusComponent";
import BusIndex from "./pages/BusIndex";
import BusUpdate from "./pages/BusUpdate";
import BusView from "./pages/BusView";
import BusCreate from "./pages/BusCreate";

const BusRouting = () => {
  return (
    <Routes>
      <Route element={<BusComponent />}>
        <Route path="/" element={<BusIndex />} />
        <Route path="/update/:id" element={<BusUpdate />} />
        <Route path="/view/:id" element={<BusView />} />
        <Route path="/create" element={<BusCreate />} />
      </Route>
      <Route path="*" element={<p>not found 404</p>} />
    </Routes>
  );
};

export default BusRouting;
