import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Booking } from "api/booking/booking";

export const useBookingCreate = () => {
  const { t } = useTranslation("index");

  let schema = yup.object().shape({
    passengers: yup.array().of(
      yup.object().shape({
        first_name: yup.string().trim().required(t("required")),
        last_name: yup.string().trim().required(t("required")),
        mother_name: yup.string().trim().required(t("required")),
        father_name: yup.string().trim().required(t("required")),
        address: yup.string().trim().required(t("required")),
        gender: yup.string().trim().required(t("required")),
        birthday: yup
          .date()
          .max(new Date(), "Birthdate cannot be in the future")
          .test(
            "is-old-enough",
            "You must be at least 15 years old",
            function (value) {
              const currentDate = new Date();
              const birthdate = new Date(value);
              const minAgeDate = new Date(
                currentDate.getFullYear() - 15,
                currentDate.getMonth(),
                currentDate.getDate()
              );
              return birthdate <= minAgeDate;
            }
          )
          .required(t("Birthdate is required"))
          .typeError(t("Birthdate is required")),
        phone: yup
          .string()
          .trim()
          .required(t("required"))
          .matches(/^\d+$/, "phone number is not valid"),
        type: yup.string().trim().required(t("required")),

        id_number: yup
          .string()
          .when("type", {
            is: "civil",
            then: yup
              .string()
              .required(t("id number is required"))
              .length(11, t("id number must be exactly 11 characters")),
            otherwise: yup.string().when("type", {
              is: "military",
              then: yup
                .string()
                .required(t("id number is required"))
                .length(7, t("Military number must be exactly 7 characters")),
              otherwise: yup.string().required(t("id number is required")),
            }),
          })
          .trim(),
      })
    ),
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, setValue, control, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { mutate } = useMutation((data) => createPost(data));
  const params = useParams();

  async function createPost(data) {
    _Booking
      .post(data, params.id)
      .then((res) => {
        setLoading(true);
        if (res.success) navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleCancel = () => navigate(-1);

  const handleReset = () => {
    const form = document.querySelector("form");
    if (form) form.reset();
  };

  const hanldeCreate = (input) => {
    const formData = new FormData();
    formData.append(`passenger_count`, input.passengers.length);
    if (input.passengers.length > 0) {
      for (let i = 0; i < input.passengers.length; i++) {
        const birthdateDate = input.passengers[i].birthday;
        const formatted_Birthday = `${birthdateDate.getFullYear()}-${(
          birthdateDate.getMonth() + 1
        )
          .toString()
          .trim()
          .padStart(2, "0")}-${birthdateDate
          .getDate()
          .toString()
          .trim()
          .padStart(2, "0")}`;
        formData.append(
          `passengers[${i}][first_name]`,
          input.passengers[i].first_name
        );
        formData.append(
          `passengers[${i}][last_name]`,
          input.passengers[i].last_name
        );
        formData.append(
          `passengers[${i}][father_name]`,
          input.passengers[i].father_name
        );
        formData.append(
          `passengers[${i}][mother_name]`,
          input.passengers[i].mother_name
        );
        formData.append(`passengers[${i}][phone]`, input.passengers[i].phone);
        formData.append(
          `passengers[${i}][address]`,
          input.passengers[i].address
        );
        input.passengers[i].email &&
          formData.append(`passengers[${i}][email]`, input.passengers[i].email);
        formData.append(`passengers[${i}][gender]`, input.passengers[i].gender);
        formData.append(`passengers[${i}][type]`, input.passengers[i].type);
        formData.append(`passengers[${i}][birthday]`, formatted_Birthday);
        formData.append(
          `passengers[${i}][id_number]`,
          input.passengers[i].id_number
        );
      }
    }

    mutate(formData);
    setLoading(true);
  };

  return {
    handleCancel,
    handleReset,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    control,
    watch,
  };
};
