import { useState } from "react";
import { useQuery } from "react-query";
import { _Profile } from "api/profile/profile";

export const useProfile = (id) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [query, setQuery] = useState("");
  const role = localStorage.getItem("role");

  const { data, isLoading, refetch } = useQuery(
    ["profile", page, count, query, role],
    () =>
      _Profile
        .index({
          query,
          page,
          count,
          role,
        })
        .then((res) => res)
  );

  return {
    data,
    isLoading,
    page,
    setPage,
    count,
    setCount,
    refetch,
    setQuery,
  };
};
