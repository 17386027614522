import { useState } from "react";
import { useQuery } from "react-query";
import { _Trip } from "api/trip/trip";

export const useTrip = (id) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [query, setQuery] = useState("");
  const [searchTo, setSearchTo] = useState("");
  const [companySearch, setCompanySearch] = useState("");

  const { data, isLoading, refetch } = useQuery(
    ["trip", page, count, query, searchTo, companySearch],
    () =>
      _Trip
        .index({
          query,
          searchTo,
          page,
          count,
          companySearch,
        })
        .then((res) => res),
    { refetchInterval: 3000 }
  );

  return {
    data,
    isLoading,
    refetch,
    page,
    setPage,
    count,
    setCount,
    setQuery,
    searchTo,
    setSearchTo,
    companySearch,
    setCompanySearch,
  };
};
