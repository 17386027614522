import { useQuery } from "react-query";
import { _Trip } from "api/trip/trip";

export const useActiveTrips = (id) => {

    const { data, isLoading, refetch } = useQuery(
        ["ActiveTrips",],
        () =>
            _Trip
                .getActive(id)
                .then((res) => res)
    );

    return {
        data,
        isLoading,
        refetch,
    };
};
