import {
  Typography,
  Box,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { BoxStyled } from "components/styled/BoxStyled";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
// import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { colorStore } from "store/ColorsStore";
import ChangeStatus from "../components/ChangeStatus";
import { useBus } from "hooks/bus/useBus";
import BusUpdate from "./BusUpdate";
import { useCallback } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useActiveCompany } from "hooks/company/useActiveCompany";

const BusIndex = () => {
  const { t } = useTranslation("index");
  const { data, page, setPage, isLoading, setSearchTo, count, searchTo } =
    useBus();

  const { data: companyData } = useActiveCompany();
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  const [direction] = settingsStore((state) => [state.direction]);

  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const columns = useMemo(() => {
    return [
      t("number"),
      t("company name"),
      t("seats count"),
      t("status"),
      t("operations"),
    ];
  }, [t]);

  const handleSearch = useDebounce((e) => {
    setSearchTo(e.target.value);
  }, 1000);

  const handleView = useCallback(
    (id) => {
      navigate(`view/${id}`);
    },
    [navigate]
  );
  // const handleEdit = useCallback(
  //   (id) => {
  //     setEditedID(id);
  //   },
  //   [setEditedID]
  // );

  const rows = useMemo(() => {
    return data?.buses?.map((bus, id) => (
      <TableRow sx={{ height: "65px" }} key={bus.id} hover>
        <TableCell sx={{ minWidth: 50 }}>{bus?.number ?? "Null"}</TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {bus?.company_name ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {bus?.seats_count ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 120 }} align="center">
          <ChangeStatus
            id={bus.id}
            action={bus.status === "Active" ? "Active" : "change-status"}
            page={page}
            count={count}
          >
            {bus.status === "Active" ? t("Active") : t("Not Active")}
          </ChangeStatus>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            minWidth: 200,
          }}
        >
          {/* <IconButton onClick={() => handleEdit(bus?.id)}>
            <Tooltip title={direction === "ltr" ? "Edit" : "تعديل"}>
              <ModeTwoToneIcon sx={{ color: "white" }} />
            </Tooltip>
          </IconButton> */}

          {/* <IconButton>
            <Tooltip title={direction === "ltr" ? "Delete" : "حذف"}>
              <DeleteDialog id={bus?.id} count={count} page={page} />
            </Tooltip>
          </IconButton> */}
          <IconButton onClick={() => handleView(bus.id)}>
            <Tooltip title={direction === "ltr" ? "View" : "مشاهدة"}>
              <VisibilityTwoToneIcon color="primary" />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [data, direction, handleView, t, page, count]);

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <>
      {isLoading && <Loader />}
      {editedID && <BusUpdate id={editedID} />}

      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("bus")}
          </Typography>

          <Button
            startIcon={<AddIcon />}
            sx={{
              color: "primary.main",
              backgroundColor: "origin.main",
              "&:hover": { backgroundColor: "origin.main" },
            }}
            onClick={handleCreate}
          >
            {t("New bus")}
          </Button>
        </Box>

        {role === "admin" && (
          <div>
            <Typography sx={{ color: "text.main", mb: "10px" }}>
              {t("Pleas Select company to show buses")}
            </Typography>
            <Select
              label={t("company")}
              sx={{ width: "30%", mb: "10px" }}
              name="company"
              id="company"
              onChange={handleSearch}
            >
              {companyData?.companies?.map((item) => (
                <MenuItem key={item} value={item?.id}>
                  <Typography sx={{ color: "text.main" }}>
                    {item?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        <BoxStyled sx={{ px: "10px" }}>
          {data?.buses?.length > 0 && (
            <Table
              columns={columns}
              rows={rows}
              page={page}
              setPage={setPage}
              count={Math.ceil(data?.pagination?.total / count)}
            />
          )}
        </BoxStyled>
      </Box>
    </>
  );
};

export default BusIndex;
