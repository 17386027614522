import { React, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldStyled } from "components/styled/TextField";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ButtonLoader from "components/shared/ButtonLoader";
import { _Employee } from "api/CompanyApi/employee";

let schema = yup.object().shape({

    new_password: yup
        .string()
        .required("Password is required")
        .min(8, "The Password must be of 8 characters")
        .max(20, "The Password must be of 20 characters"),

    password_confirmation: yup
        .string()
        .required("Confirm password is required")
        .min(8, "The confirm password must be of 8 characters")
        .max(20, "The confirm password must be of 20 characters")
        .oneOf([yup.ref("new_password")], "your password does not match"),
});

const UpdatePassword = ({ id }) => {
    const [open, setOpen] = useState()
    const [loading, setLoading] = useState()

    const { t } = useTranslation("index");
    const theme = useTheme();
    const formOptions = { resolver: yupResolver(schema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const [formData, setFormData] = useState({
        new_password: "",
        password_confirmation: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (input) => {
        setLoading(true)
        _Employee
            .changePass(input, id)
            .then((res) => {
                setLoading(false)
                if (res.success === true) setOpen(!open)
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Dialog
                open={!open}
                onClose={() => setOpen(!open)}
                size="lg"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle sx={{ color: "text.main" }}>{t("Change Passowrd")}</DialogTitle>
                <Box component="form">
                    <DialogContent>
                        <Typography sx={{ color: "text.main" }}>
                            {" "}
                            {t("Password")}
                        </Typography>
                        <TextFieldStyled
                            autoComplete=""
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            sx={{
                                input: {
                                    backgroundColor: "card.main",
                                    borderRadius: "10px",
                                    "&::placeholder": {
                                        color: "textColor.50",
                                        opacity: "0.4",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.text.main,
                                        },
                                    },
                                    color: theme.palette.text.main,
                                },
                            }}
                            type="text"
                            placeholder={t("Password")}
                            {...register("new_password")}
                            id="new_password"
                            name="new_password"
                            defaultValue={formData.new_password}
                            onChange={handleChange}
                            error={errors?.new_password?.message}
                            helperText={errors?.new_password?.message || ""}
                        />

                    </DialogContent>
                    <DialogContent>
                        <Typography sx={{ color: "text.main" }}>
                            {t("Confirm Password")}
                        </Typography>
                        <TextFieldStyled
                            autoComplete="password"
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            sx={{
                                input: {
                                    backgroundColor: "card.main",
                                    borderRadius: "10px",
                                    "&::placeholder": {
                                        color: "textColor.50",
                                        opacity: "0.4",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.text.main,
                                        },
                                    },
                                    color: theme.palette.text.main,
                                },
                            }}
                            type="text"
                            {...register("password_confirmation")}
                            placeholder={t("Confirm Password")}
                            id="password_confirmation"
                            name="password_confirmation"
                            defaultValue={formData.password_confirmation}
                            onChange={handleChange}
                            error={errors?.password_confirmation?.message}
                            helperText={errors?.password_confirmation?.message || ""}
                        />
                    </DialogContent>
                </Box>

                <DialogActions>
                    <Button sx={{ color: "text.main" }} onClick={() => setOpen(!open)}>
                        {t("Cancel")}
                    </Button>
                    <ButtonLoader name={t("submit")}
                        onClick={() => handleSubmit(onSubmit)()}
                        loading={loading}
                        disableOnLoading
                    >
                        {t("submit")}
                    </ButtonLoader>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UpdatePassword;