import React from "react";

import { Box, Chip, Typography } from "@mui/material";
import ButtonAction from "components/shared/ButtonAction";
import Loader from "components/shared/Loader";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import {
  ArrowBack,
  ArrowForward,
  EmailOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import { _Booking } from "api/booking/booking";
import { Card, CardContent } from "@mui/material";

import VerifyPassenger from "../components/VerifyPassenger";
import VerifyClient from "../components/VerifyClient";
import { AtSign, CreditCard, Home, Phone, Users } from "react-feather";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Face4OutlinedIcon from "@mui/icons-material/Face4Outlined";
import Face6OutlinedIcon from "@mui/icons-material/Face6Outlined";
import FlightClassOutlinedIcon from "@mui/icons-material/FlightClassOutlined";

const BookingView = () => {
  const { t } = useTranslation("index");
  const [direction] = settingsStore((state) => [state.direction]);
  const params = useParams();
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const { data, isLoading } = useQuery(["bookingShow"], () =>
    _Booking
      .show({
        id: params.id,
      })
      .then((res) => {
        return res.booking;
      })
  );

  const CardItem = (title, text, icon) => {
    return (
      <Typography
        sx={{
          color: "text.main",
          mt: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        component="p"
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ mx: "5px" }}>{icon}</Box>
          <Box sx={{ margin: "0px 6px" }}>{t(title)}:</Box>
        </Box>
        <div>{text}</div>
      </Typography>
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      {data && (
        <div>
          <Typography
            sx={{
              backgroundColor: "card.main",
              borderRadius: "5px",
              color: "primary.main",
              width: "40%",
              marginInline: "auto",
              textTransform: "uppercase",
              padding: "10px 20px",
              textAlign: "center",
            }}
            variant="h5"
          >
            {data?.trip_number}
          </Typography>
          <Box
            key={params.id}
            sx={{
              flexWrap: "wrap",
              marginTop: "4%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                minWidth: "40%",
                padding: "10px",
              }}
            >
              <Typography sx={{ color: "text.main", mb: "20px" }} variant="h5">
                <strong>{t("trip info")}</strong>{" "}
              </Typography>
              <Card
                style={{
                  backgroundColor: "card.main",
                  borderRadius: "10px",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                <lord-icon
                  src="https://cdn.lordicon.com/bkzzedxx.json"
                  trigger="hover"
                  colors="primary:#1c2a35,secondary:#364d5f,tertiary:#1a3244"
                  style={{ height: "150px", width: "150px" }}
                ></lord-icon>
                <Box>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("trip_number")}
                      {": "}
                    </strong>{" "}
                    {data?.trip_number}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("Trip ID")}
                      {": "}
                    </strong>{" "}
                    {data?.trip_id}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("trip_departure_time")}
                      {": "}
                    </strong>{" "}
                    {data?.trip_departure_time}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("invoice")}
                      {": "}
                    </strong>{" "}
                    {data?.invoice}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("passenger_count")}
                      {": "}
                    </strong>{" "}
                    {data?.passenger_count}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("status")}
                      {": "}
                    </strong>{" "}
                    {data?.status}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("Created at")}
                      {": "}
                    </strong>{" "}
                    {data?.created_at}
                  </Typography>
                  <Typography sx={{ color: "text.main", p: "10px" }}>
                    {" "}
                    <strong>
                      {t("pay later")}
                      {": "}
                    </strong>{" "}
                    {data?.pay_later ? (
                      <Chip label={"yes"} color="primary" variant="outlined" />
                    ) : (
                      <Chip label={"no"} color="info" variant="outlined" />
                    )}
                  </Typography>
                </Box>
              </Card>
            </Box>
            <Box sx={{ p: "10px" }}>
              <Typography sx={{ color: "text.main", mb: "20px" }} variant="h5">
                {" "}
                <strong>{t("client info")}</strong>{" "}
              </Typography>
              {data?.client && (
                <Card
                  style={{
                    backgroundColor: "card.main",
                    borderRadius: "10px",
                    padding: "10px",
                    height: "100%",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    minWidth: "40%",
                  }}
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/bhfjfgqz.json"
                    trigger="hover"
                    colors="primary:#1c2a35,secondary:#364d5f,tertiary:#1a3244"
                    style={{ height: "70px", width: "100px", margin: "0 auto" }}
                  ></lord-icon>
                  <CardContent>
                    <Typography
                      sx={{ color: "text.main", my: "20px" }}
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {data?.client?.first_name} {data?.client?.last_name}
                    </Typography>

                    {CardItem(
                      "father_name",
                      data?.client?.father_name,
                      <Face6OutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                    {CardItem(
                      "mother_name",
                      data?.client?.mother_name,
                      <Face4OutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                    <hr />
                    {CardItem(
                      "ticket",
                      data?.client?.ticket,
                      <ConfirmationNumberOutlinedIcon
                        sx={{ fontSize: "16px" }}
                      />
                    )}
                    {CardItem(
                      "id_number",
                      data?.client?.id_number,
                      <CreditCard size={16} />
                    )}
                    {CardItem(
                      "type",
                      data?.client?.type,
                      <Person2Outlined sx={{ fontSize: "16px" }} />
                    )}
                    {CardItem(
                      "gender",
                      data?.client?.gender,
                      <GroupOutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                    <hr />
                    {CardItem(
                      "email",
                      data?.client?.email,
                      <EmailOutlined sx={{ fontSize: "16px" }} />
                    )}
                    {CardItem(
                      "phone",
                      data?.client?.phone,
                      <Phone size={16} />
                    )}
                    {CardItem(
                      "address",
                      data?.client?.address,
                      <Home size={16} />
                    )}
                  </CardContent>
                  <Box sx={{ width: "100%", mt: "10px", textAlign: "center" }}>
                    {/*//* VerifyClient */}
                    <VerifyClient
                      DefualtData={data?.client}
                      id={data?.client?.id}
                      label={data?.client?.status}
                    />
                  </Box>
                </Card>
              )}
            </Box>
            <Box sx={{ p: "10px" }}>
              <Typography sx={{ color: "text.main", mb: "20px" }} variant="h5">
                {" "}
                {t("passengers")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "background.main",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {data?.passengers.length > 0 ? (
                    data?.passengers.map((item) => {
                      return (
                        <div key={item.id}>
                          <lord-icon
                            src="https://cdn.lordicon.com/eszyyflr.json"
                            trigger="hover"
                            colors="primary:#1c2a35,secondary:#364d5f,tertiary:#1a3244"
                            style={{
                              height: "100px",
                              width: "100px",
                              margin: "0 auto",
                            }}
                          ></lord-icon>
                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{ color: "text.main", mt: "5px" }}
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {item?.first_name} {item?.last_name}
                            </Typography>

                            {CardItem(
                              "father_name",
                              item?.father_name,
                              <Face6OutlinedIcon sx={{ fontSize: "16px" }} />
                            )}
                            {CardItem(
                              "mother_name",
                              item?.mother_name,
                              <Person2Outlined sx={{ fontSize: "16px" }} />
                            )}
                            {CardItem(
                              "id_number",
                              item?.id_number,
                              <CreditCard size={16} />
                            )}
                            <hr style={{ width: "100%" }} />
                            {CardItem("type", item?.type, <AtSign size={16} />)}
                            {CardItem(
                              "email",
                              item?.email,
                              <AtSign size={16} />
                            )}
                            {CardItem(
                              "phone",
                              item?.phone,
                              <Phone size={16} />
                            )}
                            <hr style={{ width: "100%" }} />
                            {CardItem(
                              "gender",
                              item?.gender,
                              <Users size={16} />
                            )}
                            {CardItem(
                              "ticket",
                              item?.ticket,
                              <ConfirmationNumberOutlinedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            )}
                          </CardContent>
                          <Box
                            sx={{
                              width: "10vw",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mt: "10px",
                            }}
                          >
                            {/*//* VerifyPassenger */}
                            <VerifyPassenger
                              DefualtData={item}
                              id={item?.id}
                              label={item?.status}
                            />
                          </Box>
                        </div>
                      );
                    })
                  ) : (
                    <Typography sx={{ color: "text.main" }}>
                      {t("No Passengers")}
                    </Typography>
                  )}
                </Card>
              </Box>
            </Box>
            <Box sx={{ p: "10px" }}>
              <Typography sx={{ color: "text.main", mb: "20px" }} variant="h5">
                {" "}
                {t("seats")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "background.main",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "none",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {data?.seats.length > 0 ? (
                    <>
                      <FlightClassOutlinedIcon
                        sx={{ color: "text.main", fontSize: "50px" }}
                      />
                      {data?.seats.map((item) => {
                        return (
                          <>
                            <CardContent
                              key={item.id}
                              style={{ margin: "10px 0px" }}
                            >
                              <Typography
                                sx={{
                                  color: "text.main",
                                  mt: "5px",
                                  border: "1px solid",
                                  borderRadius: "7px",
                                  padding: "5px 10px",
                                }}
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                {item}
                              </Typography>
                            </CardContent>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <Typography sx={{ color: "text.main" }}>
                      {t("No seats")}
                    </Typography>
                  )}
                </Card>
              </Box>
            </Box>
          </Box>
        </div>
      )}

      <div
        style={{
          minWidth: "200px",
          float: direction === "ltr" ? "right" : "left",
          marginTop: "20px",
        }}
      >
        <ButtonAction
          name={t("Back")}
          onClick={handleBack}
          endIcon={direction === "ltr" ? <ArrowForward /> : <ArrowBack />}
        />
      </div>
    </>
  );
};

export default BookingView;
