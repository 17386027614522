import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { _Profile } from "api/profile/profile";
import ButtonLoader from "components/shared/ButtonLoader";
import Loader from "components/shared/Loader";
import { TextFieldStyled } from "components/styled/TextField";
import { useProfile } from "hooks/profile/useProfile";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { colorStore } from "store/ColorsStore";
import * as yup from "yup";

let schema = yup.object().shape({
  email: yup.string().trim().required("email is required"),
  phone: yup.string().trim().required("phone is required"),
});

const ProfileUpdate = ({ id }) => {
  const { t } = useTranslation("index");

  const [setEditedID] = colorStore((state) => [state.setEditedID]);

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const { data } = useProfile();

  const details = [
    {
      head: t("email"),
      type: "text",
      placeholder: t("email"),
      name: "email",
      register: "email",
      error: "email",
      helperText: "email",
      defaultValue: data?.profile?.email,
    },
    {
      head: t("phone"),
      type: "text",
      placeholder: t("phone"),
      name: "phone",
      register: "phone",
      error: "phone",
      helperText: "phone",
      defaultValue: data?.profile?.phone,
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setEditedID(null);
  };

  const { mutate } = useMutation((UpdatedData) => createPost(UpdatedData));

  async function createPost(UpdatedData, role) {
    _Profile
      .update({
        formData: UpdatedData,
        role,
      })
      .catch((err) => {
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
        handleClose();
      });
  }

  const hanldeUpdate = (input) => {
    const formData = new FormData();
    formData.append("email", input.email);
    formData.append("phone", input.phone);
    // formData.append("_method", 'put');

    mutate(formData);
    setLoading(true);
  };

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "primary.main" }}>
          {t("Edit Row")}
        </DialogTitle>
        {!!data && (
          <>
            <Grid container component="form" key={id}>
              {details?.map((item, index) => (
                <Grid key={index} item md={6} sx={{ p: "10px" }}>
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{item.head}</Typography>
                  </Box>
                  <TextFieldStyled
                    sx={{ width: "100%" }}
                    type={item.type}
                    placeholder={item.placeholder}
                    defaultValue={item.defaultValue}
                    name={item.name}
                    {...register(item.register)}
                    error={errors[item.error]?.message}
                    helperText={errors[item.helperText]?.message || ""}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "primary.main" }}>
            {t("Cancel")}
          </Button>
          {loading && <Loader />}

          <ButtonLoader
            name={t("Submit")}
            onClick={() => handleSubmit(hanldeUpdate)()}
            type="save"
            loading={loading}
            disableOnLoading
          >
            {t("Submit")}
          </ButtonLoader>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileUpdate;
