import { useQueryClient, useMutation } from "react-query";
import { _Employee } from "api/CompanyApi/employee";
import { useSnackbar } from "notistack";

export const useDeleteEmployee = ({ page, count }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id) => _Employee.delete(id), {
    onMutate: async (id) => {
      await queryClient.cancelQueries(["employee", page, count]);
      const previousData = queryClient.getQueriesData([
        "employee",
        page,
        count,
      ]);
      queryClient.setQueryData(["employee", page, count], (oldQueryData) => {
        const oldQueryDataCopy = oldQueryData?.employees.filter(
          (old) => +old.id !== +id
        );
        return oldQueryDataCopy;
      });
      return {
        previousData,
      };
    },
    onSuccess: () => {
      enqueueSnackbar("Deleted", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return queryClient.invalidateQueries(["employee", page, count]);
    },
    onError: (_error, _hero, context) => {
      queryClient.setQueryData(
        ["employee", page, count],
        context.prevuiosQuery
      );
    },
  });
};
