import { _axios } from "interceptor/http-config";
import { storeItem, getItem, removeItem } from "./localStorage";

export const _AuthApi = {
  login: async (data) => {
    try {
      const res = await _axios.post("/admin/login", data);
      _AuthApi.storeAccessToken(res?.data?.access_token);
      _AuthApi.storeRole(res?.data?.role);
      return res;
    } catch (error) {
      // Handle error
    }
  },

  storeRole: (role) => {
    storeItem("role", role);
  },
  storeAccessToken: (access_token) => {
    storeItem("access_token", access_token);
  },

  getAccessToken: () => getItem("access_token"),
  getRole: () => getItem("role"),

  destroyToken: () => {
    removeItem("role");
    removeItem("access_token");
    window.location.reload();
  },

  changePass: (data) => {
    return _axios
      .post("admin/profile/change-password", data).then(res => res.data)
  },
};
