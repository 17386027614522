import { Typography, Box, Button, Grid, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { MenuItemStyled, SelectStyled, TextFieldStyled } from "components/styled/TextField";
import React from "react";
import Loader from "components/shared/Loader";
import ButtonAction from "components/shared/ButtonAction";
import { useCustomUsersNotifiCreate } from "../hooks/useCustomUsersNotifiCreate";
import ButtonLoader from "components/shared/ButtonLoader";

const CustomUsersNotifiCreate = () => {
    const {
        handleCancel,
        hanldeCreate,
        register,
        handleSubmit,
        handleReset,
        loading,
        t,
        errors,
        details,
        companyData,
        setCompany_id,
        TripsData,
        role
    } = useCustomUsersNotifiCreate()

    return (
        <Box>
            {loading && <Loader />}
            <Typography sx={{ color: "text.main", mb: "16px" }} variant="h5">
                {t("Create Notification")}
            </Typography>
            <Typography sx={{ color: "text.main", my: "20px" }} variant="p">
                {t("This Notification will appear to company or trip users")}
            </Typography>
            <BoxStyled sx={{ px: "24px", mt: 3 }}>
                <Box component="form">
                    <Grid container spacing={2}>
                        {/* * //details */}
                        {details.map((item, index) => (
                            <Grid key={index} xs={6} sx={{ p: "10px" }}>
                                <Box sx={{ margin: "0 0 8px 5px" }}>
                                    <Typography variant="inputTitle">{item.head}</Typography>
                                </Box>
                                <TextFieldStyled
                                    sx={{ width: "100%" }}
                                    type={item.type}
                                    placeholder={item.placeholder}
                                    name={item.name}
                                    {...register(item.register)}
                                    error={errors[item.error]?.message}
                                    helperText={errors[item.helperText]?.message || ""}
                                />
                            </Grid>
                        ))}
                        {role === 'admin' &&
                            <Grid xs={6} sx={{ p: "10px" }}>
                                <Box sx={{ margin: "0 0 8px 5px" }}>
                                    <Typography variant="inputTitle">{t('company')}</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t('company')}</InputLabel>
                                    <SelectStyled
                                        label="company"
                                        onChange={(e) => setCompany_id(e.target.value)}
                                    >
                                        {companyData?.companies?.map((item) =>
                                            <MenuItemStyled key={item} value={item?.id}>
                                                <Typography sx={{ color: 'text.main' }}>
                                                    {item?.name}
                                                </Typography>
                                            </MenuItemStyled>
                                        )}
                                    </SelectStyled>
                                </FormControl>
                            </Grid>
                        }

                        <Grid xs={6} sx={{ p: "10px" }}>
                            <Box sx={{ margin: "0 0 8px 5px" }}>
                                <Typography variant="inputTitle">{t('trip')}</Typography>
                            </Box>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('trip')}</InputLabel>
                                <SelectStyled
                                    label="company"
                                    {...register('trip_id')}
                                    error={errors.trip_id?.message}
                                    helperText={errors.trip_id?.message || ""}
                                >
                                    {TripsData?.trips?.map((item) =>
                                        <MenuItemStyled sx={{ color: 'text.main' }} key={item} value={item?.id}>
                                            <Typography sx={{ color: 'text.main' }}>
                                                {item?.trip_number}
                                            </Typography>
                                        </MenuItemStyled>
                                    )}
                                </SelectStyled>
                                <FormHelperText error>{errors.trip_id?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        mt: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                        columnGap: "15px",
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            width: "120px",
                            borderColor: "origin.main",
                            color: "primary.main",
                            "&:hover": {
                                borderColor: "origin.main",
                            },
                        }}
                        onClick={handleCancel}
                    >
                        {t("Cancel")}
                    </Button>
                    <ButtonAction
                        name={t("Reset")}
                        onClick={handleReset}
                        type="reset"
                    />

                    <ButtonLoader name={t("Submit")}
                        onClick={() => handleSubmit(hanldeCreate)()}
                        type="submit"
                        loading={loading}
                        disableOnLoading
                    >
                        {t("Submit")}
                    </ButtonLoader>
                </Box>
            </BoxStyled>
        </Box>
    );
};

export default CustomUsersNotifiCreate;
