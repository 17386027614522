import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import ShouldNotBeLogged from "./middlewares/ShouldNotBeLogged";
import ShouldBeLogged from "./middlewares/ShouldBeLogged";
import Loader from "./components/shared/Loader";
import Login from "./pages/Login";
import CompanyDashboardRouting from "modules/dashboard/CompanyDashboardRouting";
import EmployeeDashboardRouting from "modules/dashboard/EmployeeDashboardRouting";
import NotFound from "modules/notFound/404";

const DashboardRouting = React.lazy(() =>
  import("./modules/dashboard/DashboardRouting")
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const getRole = () => localStorage.getItem("role");

const getDashboardRoute = () => {
  switch (getRole()) {
    case "company":
      return (
        <Route
          path="Dashboard/company/*"
          element={
            <ShouldBeLogged>
              <CompanyDashboardRouting />
            </ShouldBeLogged>
          }
        />
      );
    case "employee":
      return (
        <Route
          path="Dashboard/employee/*"
          element={
            <ShouldBeLogged>
              <EmployeeDashboardRouting />
            </ShouldBeLogged>
          }
        />
      );
    case "admin":
      return (
        <Route
          path="Dashboard/admin/*"
          element={
            <ShouldBeLogged>
              <DashboardRouting />
            </ShouldBeLogged>
          }
        />
      );
    default:
      // Handle the case where the user's role is not recognized
      console.log(getRole());
      break;
  }
};

const AppRouting = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={
              <ShouldNotBeLogged>
                <Login />
              </ShouldNotBeLogged>
            }
          />
          {getDashboardRoute()}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </QueryClientProvider>
  );
};

export default AppRouting;
