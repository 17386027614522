
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Loader from "components/shared/Loader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useChangeStatus } from "../hooks/useChangeStatus";
import { useQueryClient } from "react-query";

const ChangeStatus = ({ id, children, action, page, count }) => {
  const { t } = useTranslation("index")
  const changeStatus = useChangeStatus({ id: id, is_blocked: action });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (e) => setOpen(true)
  const handleClose = () => setOpen(false)
  const queryClient = useQueryClient();

  const handleToggleChangeStatus = () => {
    setLoading(true);
    changeStatus.mutate({}, {
      onSuccess: () => {
        setOpen(false);
        setLoading(false);
        queryClient.invalidateQueries(["employee", page, count]);
      },
    }
    );
  };

  return (
    <>
      <Button onClick={handleClickOpen}>{children}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "&.MuiDialog-container": {
            backgroundColor: "error.main",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "primary.main", textTransform: "capitalize" }}
        >
          {action === 'Active' ? t("Un active") : t("Activate")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            {t("Are you Sure you want to")}{" "}
            {action === 'Active' ? t("Un active") : t("Activate")}
            {' ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Disagree")}</Button>
          {loading && <Loader />}
          <Button
            autoFocus
            variant="contained"
            onClick={handleToggleChangeStatus}
          >
            {t("Agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeStatus;
