import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { TextFieldStyled } from "components/styled/TextField";

import { _Bus } from "api/bus/bus";
import { useQuery } from "react-query";

export default function ControllableStates({
  defaultValue_name,
  defaultValue_id,
  setCategory,
  label,
  disabled,
  id,
}) {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const { data, refetch } = useQuery(["Bus_get_active"], () =>
    _Bus.getActive(id).then((res) => res)
  );

  useEffect(() => {
    if (data) setOptions(data?.buses);
    refetch();
  }, [data, refetch, id, setCategory]);

  useEffect(() => {
    if (defaultValue_id) {
      setValue({
        id: defaultValue_id,
        number: String(defaultValue_name),
      });
      setCategory(`bus_id`, defaultValue_id);
    }
  }, [defaultValue_id, defaultValue_name, setCategory]);

  return (
    <>
      <Autocomplete
        disabled={disabled ? disabled : false}
        value={value}
        disableClearable
        disablePortal
        onChange={(event, newValue) => {
          setCategory(`bus_id`, newValue.id ?? null);
          setValue(newValue);
        }}
        id="controllable-states-demo"
        sx={{
          color: "text.main",
          borderRadius: "50px",
          options: { color: "text.main" },
        }}
        options={options}
        getOptionLabel={(option) => String(option?.number) || ""}
        renderInput={(params) => {
          return (
            <TextFieldStyled
              {...params}
              label={label}
              sx={{
                color: "text.main",
                label: { color: "text.main" },
              }}
            />
          );
        }}
      >
        {options?.map((option) => (
          <div key={option.id}>{option.number}</div>
        ))}
      </Autocomplete>
    </>
  );
}
