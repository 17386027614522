import { _AuthApi } from "api/auth";
import { Navigate } from "react-router-dom";

const ShouldNotBeLogged = ({ children }) => {
  const role = localStorage.getItem('role')

  if (_AuthApi.getAccessToken()) {
    if (role) {
      return <Navigate to={`/dashboard/${role}`} />;
    } else {
      return <Navigate to="/" />
    }
  }

  return children;
};

export default ShouldNotBeLogged;
