import React from "react";
import { Route, Routes } from "react-router-dom";
import ProfileComponent from "./ProfileComponent";
import ProfileIndex from "./pages/ProfileIndex";
const ProfileRouting = () => {
  return (
    <Routes>
      <Route element={<ProfileComponent />}>
        <Route path="/" element={<ProfileIndex />} />
      </Route>
    </Routes>
  );
};

export default ProfileRouting;
