import {
  Typography,
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import {
  MenuItemStyled,
  SelectStyled,
  TextFieldStyled,
} from "components/styled/TextField";
import React, { useState } from "react";
import Loader from "components/shared/Loader";
import { useTripCreate } from "../hooks/useTripCreate";
import BusAutoComplete from "../../../components/shared/BusAutoComplete";

//* Rating
import Rating from "react-rating";
import { Star } from "react-feather";
import ButtonLoader from "components/shared/ButtonLoader";
import { Controller } from "react-hook-form";

const TripCreate = () => {
  const {
    handleCancel,
    hanldeCreate,
    register,
    handleSubmit,
    loading,
    t,
    errors,
    details,
    setValue,
    data,
    companyData,
    role,
    control,
  } = useTripCreate();

  const [qualityValue, setQualityValue] = useState();
  const [companyId, setCompanyId] = useState();

  return (
    <Box>
      {loading && <Loader />}
      <Typography sx={{ color: "text.main", mb: "16px" }} variant="h5">
        {t("Create Trip")}
      </Typography>
      <BoxStyled sx={{ px: "24px" }}>
        <Box component="form">
          <Grid container spacing={2}>
            {/*// * //details */}
            {details.map((item, index) => (
              <Grid key={index} xs={6} sx={{ p: "10px" }}>
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">{t(item.head)}</Typography>
                </Box>
                <TextFieldStyled
                  sx={{ width: "100%" }}
                  type={item.type}
                  placeholder={t(item.placeholder)}
                  name={item.name}
                  {...register(item.register)}
                  error={errors[item.error]?.message}
                  helperText={errors[item.helperText]?.message || ""}
                />
              </Grid>
            ))}
            {/* //* company */}
            {role === "admin" && (
              <Grid xs={6} sx={{ p: "10px" }}>
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">{t("company")}</Typography>
                </Box>
                <Controller
                  name="company_id"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("company")}
                      </InputLabel>
                      <SelectStyled
                        label="company"
                        {...field}
                        error={errors.company_id?.message}
                        helperText={errors.company_id?.message || ""}
                        inputProps={{
                          onChange: (e) => {
                            field.onChange(e.target.files);
                            setCompanyId(e.target.value);
                          },
                        }}
                      >
                        {companyData &&
                          companyData?.companies?.map((item) => (
                            <MenuItemStyled key={item} value={item?.id}>
                              <Typography sx={{ color: "text.main" }}>
                                {item?.name}
                              </Typography>
                            </MenuItemStyled>
                          ))}
                      </SelectStyled>
                      <FormHelperText error>
                        {errors.company_id?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            {/*//* bus  */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("bus")}</Typography>
              </Box>
              <BusAutoComplete
                setCategory={setValue}
                url={"buses"}
                dataName="buses"
                label={t("bus")}
                validator={{ ...register("bus_id") }}
                error={errors?.bus_id?.message}
                disabled={role === "admin" ? (companyId ? false : true) : false}
                id={companyId}
              />

              <Typography
                sx={{
                  color: "error.main",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {errors?.bus_id?.message}
              </Typography>
            </Grid>

            {/* //* category/ */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("category")}</Typography>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("category")}
                </InputLabel>
                <SelectStyled
                  label="category"
                  {...register("category")}
                  error={errors.category?.message}
                  helperText={errors.category?.message || ""}
                >
                  <MenuItemStyled value={"travel"}>
                    {" "}
                    <Typography sx={{ color: "text.main" }}>
                      {t("travel")}
                    </Typography>
                  </MenuItemStyled>
                  <MenuItemStyled value={"tourism"}>
                    {" "}
                    <Typography sx={{ color: "text.main" }}>
                      {t("tourism")}
                    </Typography>
                  </MenuItemStyled>
                </SelectStyled>
                <FormHelperText error>
                  {errors.category?.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* //* type */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("type")}</Typography>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("type")}
                </InputLabel>
                <SelectStyled
                  label="type"
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  {...register("type")}
                  error={errors.type?.message}
                  helperText={errors.type?.message || ""}
                >
                  <MenuItemStyled value={"vip"}>
                    {" "}
                    <Typography sx={{ color: "text.main" }}>
                      {t("vip")}
                    </Typography>
                  </MenuItemStyled>
                  <MenuItemStyled value={"normal"}>
                    {" "}
                    <Typography sx={{ color: "text.main" }}>
                      {t("normal")}
                    </Typography>
                  </MenuItemStyled>
                </SelectStyled>
                <FormHelperText error>{errors.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
            {/* //*from */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("from")}</Typography>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("from")}
                </InputLabel>
                <SelectStyled
                  label="type"
                  {...register("from")}
                  error={errors.from?.message}
                  helperText={errors.from?.message || ""}
                >
                  {data?.cities?.map((item, index) => (
                    <MenuItemStyled key={index} value={item.id}>
                      <Typography sx={{ color: "text.main" }}>
                        {item.name}
                      </Typography>
                    </MenuItemStyled>
                  ))}
                </SelectStyled>
                <FormHelperText error>{errors.from?.message}</FormHelperText>
              </FormControl>
            </Grid>
            {/* //*to */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("to")}</Typography>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("to")}</InputLabel>
                <SelectStyled
                  label="type"
                  {...register("to")}
                  error={errors.to?.message}
                  helperText={errors.to?.message || ""}
                >
                  {data?.cities?.map((item, index) => (
                    <MenuItemStyled key={index} value={item.id}>
                      <Typography sx={{ color: "text.main" }}>
                        {item.name}
                      </Typography>
                    </MenuItemStyled>
                  ))}
                </SelectStyled>
                <FormHelperText error>{errors.to?.message}</FormHelperText>
              </FormControl>
            </Grid>
            {/* //* quality */}
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("quality")}</Typography>
              </Box>
              <Rating
                onChange={(rate) => {
                  setValue("quality", rate);
                  setQualityValue(rate);
                }}
                initialRating={qualityValue}
                emptySymbol={<Star size={32} fill="#babfc7" stroke="#babfc7" />}
                fullSymbol={
                  <Star size={32} fill={"#266798"} stroke={"#5d9dce"} />
                }
              />
              <Typography
                sx={{
                  color: "error.main",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {errors?.quality?.message}
              </Typography>
            </Grid>
            <Grid xs={6} sx={{ p: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">
                  {t("booking_in_office")}
                </Typography>
              </Box>
              <Checkbox
                {...register("booking_in_office")}
                error={errors["booking_in_office"]?.message}
                helperText={errors["booking_in_office"]?.message || ""}
              />
              <Typography
                sx={{
                  color: "error.main",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {errors?.quality?.message}
              </Typography>
            </Grid>
            {/*  */}
            {/* <Grid xs={6} sx={{ p: "10px" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="payment from office"
                  sx={{
                    color: "text.main",
                  }}
                />
              </FormGroup>
              <Typography
                sx={{
                  color: "error.main",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {errors?.quality?.message}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "15px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              width: "120px",
              borderColor: "origin.main",
              color: "primary.main",
              "&:hover": {
                borderColor: "origin.main",
              },
            }}
            onClick={handleCancel}
          >
            {t("Cancel")}
          </Button>

          <ButtonLoader
            name={t("Submit")}
            onClick={() => handleSubmit(hanldeCreate)()}
            type="submit"
            loading={loading}
            disableOnLoading
          >
            {t("Submit")}
          </ButtonLoader>
          {/* <button onClick={() => document.querySelector("form").reset()}>test</button> */}
        </Box>
      </BoxStyled>
    </Box>
  );
};

export default TripCreate;
