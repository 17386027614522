//* React 
import React, { useState } from "react";
//* mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Tooltip } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
//* Third party components
import Loader from "components/shared/Loader";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { _Booking } from "api/booking/booking"
import { useQueryClient } from "react-query";

const ConfirmDialog = ({ id, page, count }) => {
  const { t } = useTranslation("index");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e) => setOpen(true)
  const handleClose = () => setOpen(false)

  const queryClient = useQueryClient();
  const DeleteBooking = async () => {
    setLoading(true);
    try {
      await _Booking.confirm({ id: id }).finally(() => setOpen(false));

      queryClient.invalidateQueries()
    } catch (error) {
      console.error(error);
    }

  }
  const { direction } = settingsStore();

  return (
    <React.Fragment>
      <Tooltip title={direction === "ltr" ? "confirm" : "تأكيد"}>
        <lord-icon
          src="https://cdn.lordicon.com/egiwmiit.json"
          trigger="morph"
          colors={`primary:#7cc9e0,`}
          style={{ width: 25, height: 25, margin: '0px 5px' }}
          onClick={handleClickOpen}
        ></lord-icon>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "&.MuiDialog-container": {
            backgroundColor: "error.main",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "primary.main" }}>
          {t("confirm Booking")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            {t("Are you Sure you want to confirm it ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Disagree')}</Button>
          {loading && <Loader />}
          <Button autoFocus sx={{}} variant="contained" onClick={DeleteBooking}>
            {t("Agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmDialog;
