//* React 
import React, { useState } from "react";
//* mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Tooltip } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
//* Third party components
import Loader from "components/shared/Loader";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { Typography } from "@material-ui/core";
import { TextFieldStyled } from "components/styled/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Booking } from "api/booking/booking"
import ButtonLoader from "components/shared/ButtonLoader";

const RejectDialog = ({ id, page, count }) => {
    const { t } = useTranslation("index");
    let schema = yup.object().shape({
        reject_reason: yup.string().trim().required(t("reject reason is required")),
    })

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const { mutate } = useMutation((data) => createPost(data))
    const formOptions = { resolver: yupResolver(schema) };
    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    async function createPost(data) {
        _Booking
            .Reject(data, setLoading, id)
            .then(setLoading(true))
            .finally(() => {
                setLoading(false)
                setOpen(false)
                queryClient.invalidateQueries()
            })
    }

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const queryClient = useQueryClient();

    const { direction } = settingsStore();
    const hanldeCreate = (input) => {
        const formData = new FormData()
        for (const [key, value] of Object.entries(input)) {
            formData.append(key, value);
        }
        mutate(formData);
        setLoading(true);
        queryClient.invalidateQueries()
    }
    return (
        <React.Fragment>
            <Tooltip title={direction === "ltr" ? "Reject" : "رفض"}>
                <lord-icon
                    src="https://cdn.lordicon.com/jfhbogmw.json"
                    trigger="morph"
                    colors={`primary:#d46f5d`}
                    style={{ width: 25, height: 25, mx: '0px 5px' }}
                    onClick={handleClickOpen}
                ></lord-icon>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "&.MuiDialog-container": {
                        backgroundColor: "error.main",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: "primary.main" }}>
                    {t("Reject Booking")}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: "primary.main" }}
                    >
                        {t("Are you Sure you want to Reject it ?")}
                        <Box sx={{ margin: "0 0 8px 5px" }}>
                            <Typography variant="inputTitle">{t('reject_reason')}</Typography>
                        </Box>
                        <TextFieldStyled
                            sx={{ width: "100%" }}
                            type='text'
                            placeholder={t("reject_reason")}
                            name={'reject_reason'}
                            {...register('reject_reason')}
                            error={errors['reject_reason']?.message}
                            helperText={errors['reject_reason']?.message || ""}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    {loading && <Loader />}
                    <ButtonLoader name={t("Reject")}
                        onClick={() => handleSubmit(hanldeCreate)()}
                        loading={loading}
                        disableOnLoading
                    >
                        {t("Reject")}
                    </ButtonLoader>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default RejectDialog;
