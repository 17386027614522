import {
  Typography,
  Box,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { BoxStyled } from "components/styled/BoxStyled";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { colorStore } from "store/ColorsStore";
import ChangeStatus from "../components/ChangeStatus";
import { useEmployee } from "hooks/CompanyHooks/employee/useEmployee";
import EmployeeUpdate from "./EmployeeUpdate";
import DeleteDialog from "../components/Dialog";
import { useCallback } from "react";
import { useState } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import UpdatePassword from "../components/UpdatePassword";

const EmployeeIndex = () => {
  const { t } = useTranslation("index");
  const { data, page, setPage, isLoading, count } = useEmployee();

  const navigate = useNavigate();
  const [direction] = settingsStore((state) => [state.direction]);
  const [open, setOpen] = useState();
  const [id, setId] = useState();

  const handleUpdatePass = useCallback(
    (id) => {
      setOpen(!open);
      setId(id);
    },
    [open, setId]
  );

  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const columns = useMemo(() => {
    return [
      t("full name"),
      t("email"),
      t("phone"),
      t("status"),
      t("operations"),
    ];
  }, [t]);

  const handleView = useCallback(
    (id) => {
      navigate(`view/${id}`);
    },
    [navigate]
  );
  const handleEdit = useCallback(
    (id) => {
      setEditedID(id);
    },
    [setEditedID]
  );

  const rows = useMemo(() => {
    return data?.employees?.map((employee, id) => (
      <TableRow sx={{ height: "65px" }} key={employee.id} hover>
        <TableCell sx={{ minWidth: 50 }}>
          {employee?.full_name ?? "Null"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>{employee?.email ?? "Null"}</TableCell>
        <TableCell sx={{ minWidth: 50 }}>{employee?.phone ?? "Null"}</TableCell>
        <TableCell sx={{ minWidth: 120 }} align="center">
          <ChangeStatus
            page={page}
            count={count}
            id={employee.id}
            action={employee.status === "Active" ? "Active" : "change-status"}
          >
            {employee.status === "Active" ? t("Active") : t("Not Active")}
          </ChangeStatus>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            minWidth: 200,
          }}
        >
          <IconButton onClick={() => handleEdit(employee?.id)}>
            <Tooltip title={direction === "ltr" ? "Edit" : "تعديل"}>
              <ModeTwoToneIcon sx={{ color: "white" }} />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Tooltip title={direction === "ltr" ? "Delete" : "حذف"}>
              <DeleteDialog id={employee?.id} count={count} page={page} />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleView(employee.id)}>
            <Tooltip title={direction === "ltr" ? "View" : "مشاهدة"}>
              <VisibilityTwoToneIcon color="primary" />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleUpdatePass(employee.id)}>
            <Tooltip
              title={
                direction === "ltr" ? "update password" : "تغيير كلمة المرور"
              }
            >
              <LockResetIcon />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [
    data,
    count,
    direction,
    handleEdit,
    handleView,
    handleUpdatePass,
    page,
    t,
  ]);

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <>
      {isLoading && <Loader />}
      {editedID && <EmployeeUpdate id={editedID} />}
      {open && <UpdatePassword id={id} />}
      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("employee")}
          </Typography>

          <Button
            startIcon={<AddIcon />}
            sx={{
              color: "primary.main",
              backgroundColor: "origin.main",
              "&:hover": { backgroundColor: "origin.main" },
            }}
            onClick={handleCreate}
          >
            {t("New employee")}
          </Button>
        </Box>

        <BoxStyled sx={{ px: "10px" }}>
          <Table
            columns={columns}
            rows={rows}
            page={page}
            setPage={setPage}
            count={Math.ceil(data?.pagination?.total / count)}
          />
        </BoxStyled>
      </Box>
    </>
  );
};

export default EmployeeIndex;
