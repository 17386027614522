import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _Bus = {
  view: async (id) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/buses/${id}`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },
  getActive: async (id) => {
    if (localStorage.getItem("role") === "admin" && id) {
      return _axios
        .get(`admin/buses/get-active?company_id=${id}`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data);
    } else {
      return _axios
        .get(
          `${
            localStorage.getItem("role") === "admin" ? "admin" : "company"
          }/buses/get-active`,
          {
            headers: {
              ...HttpRequestInterceptor(),
            },
          }
        )
        .then((res) => res.data);
    }
  },

  index: async ({ searchTo }) => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin"
            ? `admin/buses/get-active?company_id=${searchTo}`
            : "company/buses"
        }`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },

  post: (data, setLoading) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/buses`,
        data
      )
      .then((res) => {
        setLoading(true);
        return res.data;
      }),

  delete: (id) =>
    _axios
      .delete(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/buses/` + id
      )
      .then((res) => res.data),

  update: ({ editedID, formData }) =>
    _axios
      .post(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/buses/` + editedID,
        formData
      )
      .then((res) => res),
};
