
import { Box, Button, Container, SvgIcon } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import notFound from "../../assets/images/404.svg"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const navigate = useNavigate()
    const { t } = useTranslation("index")

    return (
        <>
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    minHeight: '100%',
                    background: "#394855",
                    height: '100vh'
                }}
            >
                <Container maxWidth="md">
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                mb: 3,
                                textAlign: 'center'
                            }}
                        >
                            <img
                                alt="Under development"
                                src={notFound}
                                style={{
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                    width: 700
                                }}
                            />
                        </Box>
                        <Button
                            startIcon={(
                                <SvgIcon fontSize="small">
                                    <ArrowLeft />
                                </SvgIcon>
                            )}
                            sx={{ mt: 3 }}
                            variant="contained"
                            onClick={() => navigate('./')}
                        >
                            {t("Go back to dashboard")}
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>)
};

export default NotFound;
