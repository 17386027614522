import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Notification } from "api/notification/notification";
import { useActiveCompany } from "hooks/company/useActiveCompany";
import { useActiveTrips } from "hooks/trip/useActiveTrips";

let schema = yup.object().shape({
    body_ar: yup.string().trim().required("body arabic is required"),
    title_en: yup.string().trim().required("title english is required"),
    body_en: yup.string().trim().required("body english is required"),
    title_ar: yup.string().trim().required("title arabic is required"),
})

export const useCustomUsersNotifiCreate = () => {
    const { t } = useTranslation("index")
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState([])
    const [company_id, setCompany_id] = useState()

    const formOptions = { resolver: yupResolver(schema) };
    const { register, handleSubmit, formState, setValue, control } = useForm(formOptions)
    const { errors } = formState
    const { mutate } = useMutation((data) => createPost(data))

    const navigate = useNavigate()
    const handleCancel = () => navigate(-1)

    const role = localStorage.getItem("role")

    const { data: companyData } = useActiveCompany();

    const { data: TripsData, refetch } = useActiveTrips(company_id);

    useEffect(() => {
        setCompany_id(null)
        refetch()
    }, [company_id, refetch])

    async function createPost(data) {
        _Notification
            .post(data, setLoading)
            .then(setLoading(true))
            .finally(() => {
                setLoading(false)
                handleReset()
            })
    }

    const handleReset = () => {
        const form = document.querySelector('form');
        setCompany_id(false)
        if (form) form.reset()
    }

    const hanldeCreate = (input) => {
        const formData = new FormData()
        for (const [key, value] of Object.entries(input)) {
            formData.append(key, value);
        }
        mutate(formData);
        setLoading(true);
    }

    useEffect(() => {
        const fields = [
            ["title_ar", "text"],
            ["body_ar", "textarea"],
            ["title_en", "text"],
            ["body_en", "textarea"],
        ]

        const data = []
        fields.map(item => {
            const key = item[0]
            const type = item[1];
            var element = {
                head: t(key.replace('_', ' ')),
                type: type,
                placeholder: t(key.replace('_', ' ')),
                name: key,
                register: key,
                error: key,
                helperText: key
            }
            data.push(element)
            return element
        })
        setDetails(data)
    }, [t])

    return {
        handleCancel,
        handleReset,
        hanldeCreate,
        register,
        handleSubmit,
        setValue,
        loading,
        t,
        errors,
        details,
        control,
        role,
        companyData,
        setCompany_id,
        TripsData
    };
};
