import { _axios } from "../../interceptor/http-config";
const role = localStorage.getItem('role')
export const _Notification = {

    post: (data, setLoading) =>
        _axios.post(role === 'admin' ? "/admin/notification/send" : "/company/sendNotification", data).then((res) => {
            setLoading(true);
        }),

}
