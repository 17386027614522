
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Booking } from "api/booking/booking";
import { settingsStore } from "store/settingsStore";
import * as yup from "yup";

export const useVerifyClient = ({ id, DefualtData }) => {
  const { t } = useTranslation("index")
  const schema = yup.object().shape({
    first_name: yup.string().trim().required(t("required")),
    last_name: yup.string().trim().required(t("required")),
    mother_name: yup.string().trim().required(t("required")),
    father_name: yup.string().trim().required(t("required")),
    address: yup.string().trim().required(t("required")),
    gender: yup.string().trim().required(t("required")),
    birthday: yup
      .date()
      .max(new Date(), t("Birthdate cannot be in the future"))
      .test(
        "is-old-enough",
        t("You must be at least 15 years old"),
        function (value) {
          const currentDate = new Date();
          const birthdate = new Date(value);
          const minAgeDate = new Date(
            currentDate.getFullYear() - 15,
            currentDate.getMonth(),
            currentDate.getDate()
          );
          return birthdate <= minAgeDate;
        }
      )
      .required(t("place_of_birth")),
    // email: yup.string().email(t('Invalid email')).trim().required(t("required")),
    phone: yup.string().trim().required(t("required")),
    type: yup.string().trim().required(t("required")),
    id_number: yup.string().trim().trim().required(t("id_number")),
  })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, setValue, control } = useForm(formOptions)
  const { errors } = formState
  const { mutate } = useMutation((data) => createPost(data))
  const [details, setDetails] = useState([])
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = () => setOpen(false)
  const { direction } = settingsStore();

  async function createPost(data) {
    _Booking
      .VerIfyClient(data, setLoading, id)
      .then(() => {
        setLoading(true)
      })
      .finally(() => {
        setLoading(false)
        setOpen(false)
        queryClient.invalidateQueries()
      })
  }

  const handleCancel = () => navigate(-1)
  const handleClickOpen = () => setOpen(true)

  const hanldeCreate = (input) => {
    setLoading(true);
    const formData = new FormData()
    const birthdateDate = input.birthday //* get birthdate value
    //* convert birthdate value into dd-mm-yyyy
    const formatted_Birthday = `${birthdateDate.getFullYear()}-${(birthdateDate.getMonth() + 1).toString().trim().padStart(2, "0")}-${birthdateDate.getDate().toString().trim().padStart(2, "0")}`;
    const inputWithoutBirthDate = { ...input } // 
    delete inputWithoutBirthDate.birthday

    for (const [key, value] of Object.entries(inputWithoutBirthDate)) {
      formData.append(key, value);
    }

    formData.append('birthday', formatted_Birthday)
    mutate(formData);

  }

  useEffect(() => {
    const fields = [
      ["first_name", "text"],
      ["last_name", "text"],
      ["mother_name", "text"],
      ["father_name", "text"],
      ["address", "text"],
      ["birthday", "date"],
      ["id_number", "number"],
      ["email", "mail"],
      ["phone", "number"],
    ]
    const data = []
    fields.map(item => {
      const key = item[0]
      const type = item[1];

      var element = {
        head: t(key).replace('_', ' '),
        type: type,
        placeholder: t(key).replace('_', ' '),
        name: key,
        register: key,
        error: key,
        helperText: key,
        value: DefualtData && DefualtData[key]
      }

      data.push(element)
      return element
    })
    setDetails(data)
  }, [t, DefualtData])

  return {
    handleCancel,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    control,
    open,
    setOpen,
    handleClose,
    queryClient,
    direction,
    handleClickOpen
  };
};

