import { Box } from "@mui/material";
import React from "react";

const LoginBg = ({ children }) => {
  const boxes = () => {
    const boxArray = [];
    for (let i = 0; i < 10; i++) {
      boxArray.push(<div key={i} className={`video-bg0 video-bg${i}`}></div>);
    }
    return boxArray;
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: 'background.main'
        }}
      >
        {boxes()}
      </Box >
      {children}
    </>
  );
};

export default LoginBg;
