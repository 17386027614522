import { _AuthApi } from "api/auth";
import { _axios as Axios } from "./http-config";
import { getItem } from "../api/localStorage";

export const HttpRequestInterceptor = () => {
  // console.warn('HttpRequestInterceptor')
  Axios.interceptors.request.use(request => {
    // console.warn('HttpRequestInterceptor use')
    const token = _AuthApi.getAccessToken();
    if (request.headers) {
      request.headers.Authorization = token ? `Bearer ${token}` : "no token";
      request.headers.Accept = "application/json";
      request.headers.lang = ["en", "ar"].includes(
        getItem("i18nextLng")
      )
        ? getItem("i18nextLng")
        : "en";
    }
    return request;
  },
    function (error) {
      return Promise.reject(error);
    }
  )
}
