import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _Statistics = {
  index: async () => {
    return _axios
      .get(
        `/${
          localStorage.getItem("role") === "admin" ? "admin" : "company"
        }/statistics`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },
};
