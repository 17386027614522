import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingsStore";

const SettingsMenu = () => {
  const { t } = useTranslation("settingMenu");

  const [mode] = settingsStore((state) => [state.mode]);

  const logOut = (input) => {
    _AuthApi.destroyToken(input);
    window.location.href = "/";
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <IconButton onClick={logOut}>
          <Tooltip title={t("Logout")}>
            {mode === "dark" ? (
              <LogoutIcon></LogoutIcon>
            ) : (
              <LogoutIcon></LogoutIcon>
            )}
          </Tooltip>
        </IconButton>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(SettingsMenu);
