import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Loader from "components/shared/Loader";
import { useStopBooking } from "hooks/trip/useStopBooking";
import { useTrip } from "hooks/trip/useTrip";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingsStore";
const StopBooking = ({ id, page, count }) => {
  const { t } = useTranslation("index");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const stopTrip = useStopBooking({ page, count });
  const handleClickOpen = (e) => setOpen(true);
  const handleClose = () => setOpen(false);
  const { refetch } = useTrip();

  const StopTrip = () => {
    setLoading(true);
    stopTrip.mutate(id, {
      onSuccess: () => {
        setOpen(false);
        setLoading(false);
        refetch();
      },
      onSettled: () => {
        setOpen(false);
      },
    });
  };

  const { direction } = settingsStore();
  return (
    <React.Fragment>
      <Tooltip title={direction === "ltr" ? "Stop trip" : "ايقاف الرحلة"}>
        <DangerousIcon onClick={handleClickOpen} />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "&.MuiDialog-container": {
            backgroundColor: "error.main",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "primary.main" }}>
          {t("stop trip")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            {t("Are you sure you want to stop it ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Disagree")}</Button>
          {loading && <Loader />}
          <Button autoFocus sx={{}} variant="contained" onClick={StopTrip}>
            {t("Agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default StopBooking;
