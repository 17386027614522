import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _City = {
  index: async ({ query, page, limit = 9 }) => {
    return _axios
      .get(`/admin/city?page=${page}&count=${limit}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data);
  },
  getActive: async () => {
    return _axios
      .get("/company/get-cities", {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data);
  },
  post: (data, setLoading, navigate) =>
    _axios.post("/admin/city", data).then((res) => {
      setLoading(true);
      navigate(-1);
    }),

  delete: (id) => _axios.delete("/admin/city/" + id).then((res) => res.data),

  update: ({ editedID, formData, loading, setLoading, setOpen }) =>
    _axios.post("/admin/city/" + editedID, formData).then((res) => {
      setLoading(false);
      setOpen(false);
    }),
};
