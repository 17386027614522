import { React, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { colorStore } from "store/ColorsStore";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MenuItemStyled,
  SelectStyled,
  TextFieldStyled,
} from "components/styled/TextField";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { _Trip } from "api/trip/trip";
import Loader from "components/shared/Loader";
import ButtonLoader from "components/shared/ButtonLoader";

function getErrorMessage(name) {
  const msg = name?.replaceAll("_", " ");
  return `${msg}: is requeried`;
}

let schema = yup.object().shape({
  booking_in_office: yup.boolean(),
  category: yup.string().trim().required(getErrorMessage("category")),
  trip_number: yup.string().trim().required(getErrorMessage("trip_number")),
  driver_name_en: yup
    .string()
    .trim()
    .required(getErrorMessage("driver_name_en")),
  driver_name_ar: yup
    .string()
    .trim()
    .required(getErrorMessage("driver_name_ar")),
  attendant_name_en: yup
    .string()
    .trim()
    .required(getErrorMessage("attendant_name_en")),
  attendant_name_ar: yup
    .string()
    .trim()
    .required(getErrorMessage("attendant_name_ar")),
  departure_time: yup
    .string()
    .transform((newValue, originalValue) => {
      if (originalValue === null || originalValue === "") {
        return null;
      }
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedDate;
    })
    .trim()
    .required("departure time is required"),
  arrival_time: yup
    .string()
    .transform((newValue, originalValue) => {
      if (originalValue === null || originalValue === "") {
        return null;
      }
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedDate;
    })
    .trim()
    .required("Arrival time is required"),
});

const TripUpdate = ({ id }) => {
  const { t } = useTranslation("index");
  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(
    ["trip", "id-" + editedID],
    async () => {
      return await _Trip.view(editedID).then((res) => {
        return res.trip;
      });
    },
    {}
  );

  const details = [
    {
      head: t("trip number"),
      type: "text",
      placeholder: t("trip_number").replace("_", " "),
      name: "trip_number",
      register: "trip_number",
      error: "trip_number",
      helperText: "trip_number",
      defaultValue: data?.trip_number,
    },
    {
      head: t("driver name en"),
      type: "text",
      placeholder: t("driver_name_en").replace("_", " "),
      name: "driver_name_en",
      register: "driver_name_en",
      error: "driver_name_en",
      helperText: "driver_name_en",
      defaultValue: data?.translations[1]?.driver_name,
    },
    {
      head: t("driver name ar"),
      type: "text",
      placeholder: t("driver_name_ar").replace("_", " "),
      name: "driver_name_ar",
      register: "driver_name_ar",
      error: "driver_name_ar",
      helperText: "driver_name_ar",
      defaultValue: data?.translations[0]?.driver_name,
    },
    {
      head: t("attendant name ar"),
      type: "text",
      placeholder: t("attendant_name_ar").replace("_", " "),
      name: "attendant_name_ar",
      register: "attendant_name_ar",
      error: "attendant_name_ar",
      helperText: "attendant_name_ar",
      defaultValue: data?.translations[0]?.attendant_name,
    },
    {
      head: t("attendant name en"),
      type: "text",
      placeholder: t("attendant_name_en").replace("_", " "),
      name: "attendant_name_en",
      register: "attendant_name_en",
      error: "attendant_name_en",
      helperText: "attendant_name_en",
      defaultValue: data?.translations[1]?.attendant_name,
    },
    {
      head: t("departure time"),
      type: "datetime-local",
      placeholder: t("departure_time").replace("_", " "),
      name: "departure_time",
      register: "departure_time",
      error: "departure_time",
      helperText: "departure_time",
      defaultValue: data?.departure_time,
    },
    {
      head: t("arrival time"),
      type: "datetime-local",
      placeholder: t("arrival_time").replace("_", " "),
      name: "arrival_time",
      register: "arrival_time",
      error: "arrival_time",
      helperText: "arrival_time",
      defaultValue: data?.arrival_time,
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setEditedID(null);
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation((data) => createPost(data));

  async function createPost(data) {
    _Trip
      .update({
        editedID: editedID,
        formData: data,
      })
      .catch((err) => {
        setLoading(false);
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.success === true) handleClose();
        queryClient.invalidateQueries();
      });
  }

  const hanldeUpdate = (input) => {
    const formData = new FormData();
    formData.append("driver_name_ar", input.driver_name_ar);
    formData.append("attendant_name_ar", input.attendant_name_ar);
    formData.append("driver_name_en", input.driver_name_en);
    formData.append("attendant_name_en", input.attendant_name_en);
    formData.append("category", input.category);
    formData.append("trip_number", input.trip_number);
    if (input.booking_in_office) formData.append("booking_in_office", "1");
    else formData.append("booking_in_office", "0");
    formData.append(
      "arrival_time",
      input.arrival_time === "NaN-NaN-NaN NaN:NaN"
        ? data?.arrival_time
        : input.arrival_time
    );
    formData.append(
      "departure_time",
      input.departure_time === "NaN-NaN-NaN NaN:NaN"
        ? data?.departure_time
        : input.departure_time
    );
    formData.append("_method", "put");

    mutate(formData);
    setLoading(true);
  };

  return (
    <>
      {!data && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "primary.main" }}>
          {t("Edit Row")}
        </DialogTitle>
        {data ? (
          <>
            <Grid container component="form" key={id}>
              {details?.map((item, index) => (
                <Grid key={index} item xs={6} sx={{ p: "10px" }}>
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{item.head}</Typography>
                  </Box>
                  <TextFieldStyled
                    sx={{ width: "100%" }}
                    type={item.type}
                    placeholder={item.placeholder}
                    defaultValue={item.defaultValue}
                    name={item.name}
                    {...register(item.register)}
                    error={errors[item.error]?.message}
                    helperText={errors[item.helperText]?.message || ""}
                  />
                </Grid>
              ))}

              {/* //* category/ */}
              <Grid xs={6} sx={{ p: "10px" }}>
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">{t("category")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("category")}
                  </InputLabel>
                  <SelectStyled
                    sx={{ color: "text.main", borderColor: "text.main" }}
                    label="category"
                    {...register("category")}
                    error={errors.category?.message}
                    helperText={errors.category?.message || ""}
                    defaultValue={data?.category}
                  >
                    <MenuItemStyled value={"travel"}>
                      <Box sx={{ color: "text.main" }}>{"travel"}</Box>
                    </MenuItemStyled>
                    <MenuItemStyled value={"tourism"}>
                      <Box sx={{ color: "text.main" }}>{"tourism"}</Box>
                    </MenuItemStyled>
                  </SelectStyled>
                  <FormHelperText error>
                    {errors.category?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={6} sx={{ p: "10px" }}>
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("booking_in_office")}
                  </Typography>
                </Box>
                <Checkbox
                  {...register("booking_in_office")}
                  defaultChecked={data?.booking_in_office}
                  error={errors["booking_in_office"]?.message}
                  helperText={errors["booking_in_office"]?.message || ""}
                />
                <Typography
                  sx={{
                    color: "error.main",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {errors?.quality?.message}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "primary.main" }}>
            {t("Cancel")}
          </Button>
          {loading && <Loader />}
          <ButtonLoader
            name={t("Submit")}
            onClick={() => handleSubmit(hanldeUpdate)()}
            type="save"
            loading={loading}
            disableOnLoading
          >
            {t("Submit")}
          </ButtonLoader>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TripUpdate;
