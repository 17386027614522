import { Box, Typography } from "@mui/material";
import ButtonAction from "components/shared/ButtonAction";
import Loader from "components/shared/Loader";
import { _axios } from "interceptor/http-config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { BoxStyled } from "components/styled/BoxStyled";
const ReviewView = () => {
  const { t } = useTranslation("index");
  const [direction] = settingsStore((state) => [state.direction]);
  const params = useParams();
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const role = localStorage.getItem("role");
  const { data, isLoading } = useQuery(
    ["review", "id-" + params.id],
    async () => {
      return await _axios
        .get(`/${role}/reviews/` + params.id, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.review);
    },
    {}
  );

  const columns = [
    { head: t("client name"), value: data?.client_name },
    { head: t("trip number"), value: data?.trip_number },
    { head: t("rate"), value: data?.rate },
    { head: t("review"), value: data?.review },
  ];

  return (
    <>
      {isLoading && <Loader />}
      {!!data && (
        <Box key={params.id} sx={{}}>
          <BoxStyled
            hover
            sx={{
              display: "flex",
              color: "text.main",
              height: "100%",
              flexWrap: "wrap",
              columnGap: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "card.main",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2.1,
                  width: "100%",
                }}
              >
                <h3>{t("Details")}</h3>
                <Box>
                  {columns?.map((item, id) => (
                    <Box
                      key={id}
                      sx={{
                        pl: "10px",
                        my: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: "700",
                          fontSize: "15px",
                          marginInlineEnd: "15px",
                        }}
                      >
                        {item.head}:
                      </Typography>
                      <Typography variant="p">
                        {typeof item?.value === "object"
                          ? JSON.stringify(item?.value)
                          : item?.value ?? "null"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </BoxStyled>
        </Box>
      )}

      <div
        style={{
          minWidth: "200px",
          float: direction === "ltr" ? "right" : "left",
          marginTop: "20px",
        }}
      >
        <ButtonAction
          name={t("Back")}
          onClick={handleBack}
          endIcon={direction === "ltr" ? <ArrowForward /> : <ArrowBack />}
        />
      </div>
    </>
  );
};

export default ReviewView;
