
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Bus } from "api/bus/bus";

let schema = yup.object().shape({
  number: yup.number().required("number is required").typeError("number is required"),
  seats_count: yup
    .number()
    .max(60, "The seats count field must not be greater than 60.")
    .required("seats_count is required").typeError("seats_count is required"),
})
let adminSchema = yup.object().shape({
  number: yup.number().required("number is required").typeError("number is required"),
  company_id: yup.number().required("company is required").typeError("company is required"),
  seats_count: yup
    .number()
    .max(60, "The seats count field must not be greater than 60.")
    .required("seats_count is required").typeError("seats_count is required"),
})

export const useBusCreate = () => {
  const { t } = useTranslation("index")
  const role = localStorage.getItem("role")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const returnSchema = () => {
    if (role !== 'admin')
      return schema
    else return adminSchema
  }
  const formOptions = { resolver: yupResolver(returnSchema()) };
  const { register, handleSubmit, formState, setValue, control } = useForm(formOptions)
  const { errors } = formState
  const { mutate } = useMutation((data) => createPost(data))
  const [details, setDetails] = useState([])

  async function createPost(data) {
    _Bus
      .post(data, setLoading)
      .then(res => {
        setLoading(true)
        if (res.success) navigate(-1)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => navigate(-1)

  //* the first value is the input name 
  //* the second value is the input type
  const fields = [
    ["number", "number"],
    ["seats_count", "number"],
  ]

  const handleReset = () => {
    const form = document.querySelector('form');
    if (form) form.reset()
  }
  const hanldeCreate = (input) => {
    const formData = new FormData()
    const inputWithoutBirthday = { ...input };
    delete inputWithoutBirthday.birthday;
    for (const [key, value] of Object.entries(inputWithoutBirthday)) {
      formData.append(key, value);
    }
    mutate(formData);
    setLoading(true);
  }

  const items = () => {
    const data = []
    fields.map(item => {
      const key = item[0]
      const type = item[1];
      var element = {
        head: t(key).replace('_', ' '),
        type: type,
        placeholder: t(key).replace('_', ' '),
        name: key,
        register: key,
        error: key,
        helperText: key
      }
      data.push(element)
    })
    setDetails(data)
  }

  useEffect(() => items(), [])

  return {
    handleCancel,
    handleReset,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    control,

  };
};

