import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Trip } from "api/trip/trip";
import { _City } from "api/city/city";
import { useActiveCompany } from "hooks/company/useActiveCompany";

function getErrorMessage(name) {
  const msg = name?.replaceAll("_", " ");
  return `${msg}: is requeried`;
}

let schema = yup.object().shape({
  booking_in_office: yup.boolean().default(false),
  type: yup.string().trim().required(getErrorMessage("type")),
  bus_id: yup.string().trim().required(getErrorMessage("bus_id")),
  category: yup.string().trim().required(getErrorMessage("category")),
  trip_number: yup.string().trim().required(getErrorMessage("trip_number")),
  driver_name_en: yup
    .string()
    .trim()
    .required(getErrorMessage("driver_name_en")),
  driver_name_ar: yup
    .string()
    .trim()
    .required(getErrorMessage("driver_name_ar")),
  attendant_name_en: yup
    .string()
    .trim()
    .required(getErrorMessage("attendant_name_en")),
  attendant_name_ar: yup
    .string()
    .trim()
    .required(getErrorMessage("attendant_name_ar")),
  quality: yup.number().required(getErrorMessage("quality")),
  ticket_price: yup.string().trim().required(getErrorMessage("ticket_price")),
  departure_time: yup
    .string()
    .transform((newValue, originalValue) => {
      if (originalValue === null || originalValue === "") {
        return null;
      }
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedDate;
    })
    .trim()
    .required("departure time is required")
    .typeError("departure time is required"),
  arrival_time: yup
    .string()
    .transform((newValue, originalValue) => {
      if (originalValue === null || originalValue === "") {
        return null;
      }
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedDate;
    })
    .trim()
    .required("Arrival time is required")
    .typeError("Arrival time is required"),
  from: yup.string().trim().required(getErrorMessage("from")),
  to: yup
    .string()
    .notOneOf([yup.ref("from")], "you can not go to the same city")
    .trim()
    .required(getErrorMessage("to")),
});

let companyValidation = yup.object().shape({
  company_id: yup
    .string()
    .required("This field is required")
    .typeError("This field is required"),
});

export const useTripCreate = () => {
  const { data: companyData } = useActiveCompany();

  const role = localStorage.getItem("role");

  if (role === "admin") schema = schema.concat(companyValidation);

  const { t } = useTranslation("index");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, setValue, control, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { mutate } = useMutation((data) => createPost(data));
  const [details, setDetails] = useState([]);

  const { data } = useQuery(["city_get_active"], () =>
    _City.getActive().then((res) => res)
  );

  async function createPost(data) {
    _Trip
      .post(data, setLoading)
      .then((res) => {
        setLoading(true);
        if (res.success === true) {
          navigate(-1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleCancel = () => navigate(-1);

  const hanldeCreate = (input) => {
    const formData = new FormData();
    const inputWithoutBirthday = { ...input };
    delete inputWithoutBirthday.birthday;
    for (const [key, value] of Object.entries(inputWithoutBirthday)) {
      if (key !== "booking_in_office") formData.append(key, value);
      else {
        if (value === true) formData.append(key, "1");
        else formData.append(key, "0");
      }
    }
    mutate(formData);
    setLoading(true);
  };

  useEffect(() => {
    const fields = [
      ["driver_name_ar", "text"],
      ["attendant_name_ar", "text"],
      ["driver_name_en", "text"],
      ["attendant_name_en", "text"],
      ["ticket_price", "number"],
      ["trip_number", "number"],
      ["departure_time", "datetime-local"],
      ["arrival_time", "datetime-local"],
    ];

    const data = [];
    fields.map((item) => {
      const key = item[0];
      const type = item[1];
      var element = {
        head: key.replaceAll("_", " "),
        type: type,
        placeholder: key.replaceAll("_", " "),
        name: key,
        register: key,
        error: key,
        helperText: key,
      };
      data.push(element);
      return data;
    });
    setDetails(data);
  }, []);

  return {
    handleCancel,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    control,
    data,
    companyData,
    role,
    watch,
  };
};
