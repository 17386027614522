import React, { useState, useMemo } from "react";
import { Drawer } from "../styled/Drawer";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SideBarHeader from "./SideBarHeader";
import SideBarLink from "./SideBarLink";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import PublicIcon from "@mui/icons-material/Public";
// * icons
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import { Star } from "react-feather";

const SideBar = ({ open, setOpen, active, icon, text }) => {
  const { t } = useTranslation("sidebar");

  const [hoverd, setHoverd] = useState(false);

  const handleMouseEnter = () => {
    setHoverd(true);
  };
  const handleMouseLeave = () => {
    setHoverd(false);
  };
  const getRole = () => localStorage.getItem("role");

  const links = useMemo(
    () => [
      {
        name: t("Profile"),
        link: "/dashboard/admin/profile",
        icon: <AccountBoxIcon />,
      },
      {
        name: t("Admin"),
        link: "/dashboard/admin/admin",
        icon: <AdminPanelSettingsIcon />,
      },

      {
        name: t("Companies"),
        link: "/dashboard/admin/company",
        icon: <ApartmentIcon />,
      },
      {
        name: t("Clients"),
        link: "/dashboard/admin/clients",
        icon: <GroupIcon />,
      },
      {
        name: t("Employee"),
        link: "/dashboard/admin/employee",
        icon: <GroupsIcon />,
      },
      {
        name: t("trip"),
        link: "/dashboard/admin/trip",
        icon: <ModeOfTravelIcon />,
      },
      {
        name: t("bus"),
        link: "/dashboard/admin/bus",
        icon: <DirectionsBusIcon />,
      },
      {
        name: t("City"),
        link: "/dashboard/admin/city",
        icon: <PublicIcon />,
      },
      {
        name: t("Notification"),
        link: "/dashboard/admin/Notification/create",
        icon: <NotificationsIcon />,
      },
      {
        name: t("Notification Custom"),
        link: "/dashboard/admin/NotificationCustom/create",
        icon: <NotificationsIcon />,
      },
      {
        name: t("Reviews"),
        link: "/dashboard/admin/review",
        icon: <Star />,
      },
    ],
    [t]
  );

  const CompanyLinks = useMemo(
    () => [
      {
        name: t("Profile"),
        link: "/dashboard/company/profile",
        icon: <AccountBoxIcon />,
      },
      {
        name: t("bus"),
        link: "/dashboard/company/bus",
        icon: <DirectionsBusIcon />,
      },
      {
        name: t("trip"),
        link: "/dashboard/company/trip",
        icon: <ModeOfTravelIcon />,
      },
      {
        name: t("employee"),
        link: "/dashboard/company/employee",
        icon: <GroupsIcon />,
      },
      {
        name: t("Reviews"),
        link: "/dashboard/company/review",
        icon: <Star />,
      },
      {
        name: t("Notification Custom"),
        link: "/dashboard/company/NotificationCustom/create",
        icon: <NotificationsIcon />,
      },
    ],
    [t]
  );

  const EmployeeLinks = useMemo(
    () => [
      {
        name: t("Profile"),
        link: "/dashboard/employee/profile",
        icon: <AccountBoxIcon />,
      },
      {
        name: t("bus"),
        link: "/dashboard/employee/bus",
        icon: <DirectionsBusIcon />,
      },
      {
        name: t("trip"),
        link: "/dashboard/employee/trip",
        icon: <ModeOfTravelIcon />,
      },
    ],
    [t]
  );

  const renderLinks = () => {
    switch (getRole()) {
      case "admin":
        return links.map((link, index) => (
          <NavLink to={link.link} key={index}>
            {({ isActive }) => (
              <SideBarLink
                text={t(link.name)}
                active={isActive}
                icon={link.icon}
                open={open || hoverd}
              />
            )}
          </NavLink>
        ));
      case "company":
        return CompanyLinks.map((link, index) => (
          <NavLink to={link.link} key={index}>
            {({ isActive }) => (
              <SideBarLink
                text={t(link.name)}
                active={isActive}
                icon={link.icon}
                open={open || hoverd}
              />
            )}
          </NavLink>
        ));
      case "employee":
        return EmployeeLinks.map((link, index) => (
          <NavLink to={link.link} key={index}>
            {({ isActive }) => (
              <SideBarLink
                text={t(link.name)}
                active={isActive}
                icon={link.icon}
                open={open || hoverd}
              />
            )}
          </NavLink>
        ));
      default:
        console.log(getRole());
        break;
    }
  };
  return (
    <Drawer
      variant="permanent"
      open={open}
      hoverd={hoverd ? hoverd : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "background.sidebar",
          backdropFilter: "blur(6px)",
        },
      }}
    >
      <SideBarHeader
        open={open}
        setOpen={setOpen}
        hoverd={hoverd ? hoverd : ""}
      />
      <Box
        sx={{
          padding: "0 16px",
          pt: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "4px",
        }}
      >
        {renderLinks()}
      </Box>
    </Drawer>
  );
};

export default SideBar;
