import { useTheme } from "@emotion/react";
import {
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { BoxStyled } from "components/styled/BoxStyled";
import { TextFieldStyled } from "components/styled/TextField";
import { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function InputRepeater({
  control,
  errors,
  register,
  setPassengers,
  watch,
}) {
  const [inputs, setInputs] = useState([
    {
      first_name: "",
      last_name: "",
      mother_name: "",
      father_name: "",
      address: "",
      birthday: "",
      id_number: "",
      email: "",
      phone: "",
    },
  ]);

  useEffect(() => {
    setPassengers("passengers", inputs ?? null);
  }, [inputs, setPassengers]);

  const { t } = useTranslation("index");

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        first_name: "",
        last_name: "",
        mother_name: "",
        father_name: "",
        address: "",
        birthday: "",
        id_number: "",
        email: "",
        phone: "",
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (event, index, field) => {
    const newInputs = [...inputs];
    newInputs[index][field] = event.target.value;
    setInputs(newInputs);
  };

  const fields = [
    ["first_name", "text"],
    ["last_name", "text"],
    ["mother_name", "text"],
    ["father_name", "text"],
    ["address", "text"],
    ["birthday", "date"],
    ["email", "mail"],
    ["phone", "text"],
  ];

  const [details, setDetails] = useState([]);

  const items = () => {
    const data = [];
    fields.map((item) => {
      const key = item[0];
      const type = item[1];

      var element = {
        head: t(key).replace("_", " "),
        type: type,
        placeholder: t(key).replace("_", " "),
        name: key,
        register: key,
        error: key,
        helperText: key,
      };
      data.push(element);
    });
    setDetails(data);
  };

  useEffect(() => items(), []);

  const theme = useTheme();

  return (
    <>
      <h4>{t("passenger")}</h4>
      {inputs?.map((input, index) => (
        <BoxStyled
          sx={{
            p: 5,
            m: 1,
          }}
        >
          <Grid container spacing={2} key={index}>
            {inputs.length > 1 ? (
              <Grid xs="12" sx={{ p: "30px", mt: "20px" }}>
                <Button onClick={() => handleRemoveInput(index)}>
                  <X />
                </Button>
              </Grid>
            ) : null}

            {details.map((item, x) => (
              <Grid key={x} xs={6} sx={{ p: "10px", mt: "10px" }}>
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">{item.head}</Typography>
                </Box>
                <Controller
                  control={control}
                  {...register(`passengers.${index}.${item.register}`)}
                  render={({ field }) => (
                    <TextFieldStyled
                      sx={{ width: "100%" }}
                      type={item.type}
                      placeholder={item.placeholder}
                      name={item.name}
                      error={errors?.passengers?.[index]?.[item.error]}
                      helperText={
                        errors?.passengers?.[index]?.[item.error]?.message
                      }
                      onChange={(event) => {
                        handleInputChange(event, index, item.name);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
              </Grid>
            ))}

            <Grid xs={6} sx={{ p: "10px", mt: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("type")}</Typography>
              </Box>
              <Controller
                control={control}
                name={`passengers.${index}.type`}
                setType={(value) => {
                  setPassengers(`passengers.${index}.type`, value);
                }}
                render={({ field }) => (
                  <Select
                    fullWidth
                    {...field}
                    helperText={errors?.passengers?.[index]?.type?.message}
                    error={errors?.passengers?.[index]?.type}
                    onChange={(event) => {
                      field.onChange(event);
                      handleInputChange(event, index, "type");
                    }}
                    sx={{ color: theme.palette.text.main }}
                  >
                    <MenuItem
                      sx={{ color: theme.palette.text.main }}
                      value="military"
                    >
                      {t("military")}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: theme.palette.text.main }}
                      value="civil"
                    >
                      {t("civil")}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: theme.palette.text.main }}
                      value="foreign"
                    >
                      {t("foreign")}
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText style={{ color: "#d8364c" }}>
                {errors?.passengers?.[index]?.type?.message}
              </FormHelperText>
            </Grid>
            <Grid xs={6} sx={{ p: "10px", mt: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">
                  {watch(`passengers.${index}.type`) === "military"
                    ? t("Military Number")
                    : t("id_number")}{" "}
                </Typography>
              </Box>

              <Controller
                control={control}
                {...register(`passengers.${index}.id_number`)}
                render={({ field }) => (
                  <TextFieldStyled
                    sx={{ width: "100%" }}
                    type="number"
                    placeholder={
                      watch(`passengers.${index}.type`) === "military"
                        ? t("Military Number")
                        : t("id_number")
                    } // Change title based on 'type'
                    name={`passengers.${index}.id_number`}
                    error={errors?.passengers?.[index]?.id_number}
                    helperText={errors?.passengers?.[index]?.id_number?.message}
                    onChange={(event) => {
                      handleInputChange(event, index, "id_number");
                      field.onChange(event);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid xs={6} sx={{ p: "10px", mt: "10px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("gender")}</Typography>
              </Box>
              <Controller
                control={control}
                name={`passengers.${index}.gender`}
                render={({ field }) => (
                  <Select
                    fullWidth
                    {...field}
                    helperText={errors?.passengers?.[index]?.gender?.message}
                    error={errors?.passengers?.[index]?.gender}
                    onChange={(event) => {
                      field.onChange(event);
                      handleInputChange(event, index, "gender");
                    }}
                    sx={{ color: theme.palette.text.main }}
                  >
                    <MenuItem
                      sx={{ color: theme.palette.text.main }}
                      value="male"
                    >
                      {" "}
                      {t("male")}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: theme.palette.text.main }}
                      MenuItem
                      value="female"
                    >
                      {" "}
                      {t("female")}
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText style={{ color: "#d8364c" }}>
                {errors?.passengers?.[index]?.gender?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </BoxStyled>
      ))}

      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: 4,
        }}
        onClick={handleAddInput}
      >
        <Plus />
        <span>{t("Add Passenger")} </span>
      </Button>
    </>
  );
}
