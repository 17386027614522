
import { Box, Typography } from "@mui/material";
import ButtonAction from "components/shared/ButtonAction";
import Loader from "components/shared/Loader";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { _Trip } from "api/trip/trip";
const TripView = () => {
  const { t } = useTranslation("index");
  const [direction] = settingsStore((state) => [state.direction]);
  const params = useParams();
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const { data, isLoading } = useQuery(
    ["trip", 'id-' + params.id],
    async () => {
      return await _Trip.view(params.id).then((res) => {

        return res.trip
      })
    }, {}
  )
  const columns = [
    { head: t("company name"), value: data?.company_name },
    { head: t("departure time"), value: data?.departure_time },
    { head: t("arrival time"), value: data?.arrival_time },
    { head: t("ticket price"), value: data?.ticket_price },
    { head: t("available seats"), value: data?.available_seats },
    { head: t("type"), value: data?.type },
    { head: t("category"), value: data?.category },
    { head: t("trip number"), value: data?.trip_number },
    { head: t("bus number"), value: data?.bus_number },
    { head: t("driver name"), value: data?.driver_name },
    { head: t("attendant name"), value: data?.attendant_name },
  ]

  return (
    <>
      {isLoading && <Loader />}
      <h2 style={{ color: 'white' }}>text</h2>
      {data && (
        <div>
          <Typography
            sx={{
              backgroundColor: "card.main",
              borderRadius: "5px",
              color: 'primary.main',
              width: "40%",
              marginInline: 'auto',
              height: "100%",
              textTransform: "uppercase",
              padding: '10px 20px',
              textAlign: 'center'
            }}
            variant="h5"
          >
            {data.trip_number}
          </Typography>
          <Box
            key={params.id}
            sx={{
              display: "flex",
              color: "lightGray.main",
              columnGap: 10,
              marginTop: "4%",
              justifyContent: "center",
            }}
          >
            <Box
              hover
              sx={{
                display: "flex",
                justifyContent: 'center',
                color: "text.main",
                height: "100%",
                flexWrap: 'wrap',
                columnGap: 2,
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  backgroundColor: "card.main",
                  borderRadius: "5px",
                  padding: '20px'
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2.1,
                  }}
                >
                  <h3>
                    {t("Details")}
                  </h3>
                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: "wrap",
                  }}>
                    {columns?.map((item, id) => (
                      <Box
                        key={id}
                        sx={{
                          display: "flex",
                          pl: "10px",
                          width: "50%",
                          my: '5px'
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: "700",
                            fontSize: "15px",
                            marginInlineEnd: "15px",
                          }}
                        >
                          {item.head}:
                        </Typography>
                        <Typography variant="p">
                          {typeof item?.value === "object"
                            ? JSON.stringify(item?.value)
                            : item?.value ?? "null"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{
                width: "30%",
              }}>
                <img style={{ width: '100%', margin: '1rem', marginInline: 'auto' }} src={data?.image} alt=" " />
              </Box>
            </Box>
          </Box>
        </div>
      )}

      <div
        style={{
          minWidth: "200px",
          float: direction === "ltr" ? "right" : "left",
          marginTop: "20px",
        }}
      >
        <ButtonAction
          name={t("Back")}
          onClick={handleBack}
          endIcon={direction === "ltr" ? <ArrowForward /> : <ArrowBack />}
        />
      </div>
    </>
  );
};

export default TripView;
