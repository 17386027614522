import { Typography, Box, TableRow, TableCell, Button, IconButton, Tooltip } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { useProfile } from "hooks/profile/useProfile";
import ChangePassword from "../components/ChangePassword.jsx"
import { colorStore } from "store/ColorsStore.js";
import { settingsStore } from "store/settingsStore.js";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import ProfileUpdate from "./ProfileUpdate.jsx";

const ProfileIndex = () => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useProfile();
  const [open, setOpen] = useState(false);

  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const columns = useMemo(() => {
    return [t("Name"), t("Email"), t("Phone"), t("update")];
  }, [t]);

  const handleEdit = useCallback((id) => { setEditedID(id) }, [setEditedID])
  const [direction] = settingsStore((state) => [state.direction]);
  const role = localStorage.getItem('role')

  const rows = useMemo(() => {
    return (
      data && (
        <TableRow sx={{ height: "65px" }} key={data.id}>
          <TableCell sx={{ minWidth: 50 }}>
            {data?.profile?.name ?? "Null"}
          </TableCell>

          <TableCell sx={{ minWidth: 120 }} align="center">
            {data?.profile?.email ?? "Null"}
          </TableCell>

          <TableCell sx={{ minWidth: 120 }} align="center">
            {data?.profile?.phone ?? "Null"}
          </TableCell>
          {role === 'company' &&
            <IconButton onClick={() => handleEdit(data?.profile?.id)}>
              <Tooltip title={direction === "ltr" ? "Edit" : "تعديل"}>
                <ModeTwoToneIcon sx={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          }

          <Button onClick={() => setOpen(!open)}>
            {t('change password')}
          </Button>
        </TableRow>
      )
    );
  }, [data, open, t, direction, handleEdit, role]);

  return (
    <>
      {isLoading && <Loader />}
      {open && <ChangePassword />}
      {editedID && <ProfileUpdate id={editedID} />}
      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("Profile")}
          </Typography>
        </Box>
        <BoxStyled sx={{ px: "10px" }}>
          <Table
            columns={columns}
            rows={rows}
          />
        </BoxStyled>
      </Box>
    </>
  );
};

export default ProfileIndex;
