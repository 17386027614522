
const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const divider = "#E6E8F0";

const colors = {
  origin: {
    main: "#E6F4F1",
  },
  background: {
    main: "#F0FBFF",
    paper: "#cee7f1",
    login: "#ccdbfd14",
    hover: '#dbf7ff'
  },
  edit: {
    main: "#d5e9f1",
  },
  text: {
    main: "#1c2a35",
  },
  primary: {
    main: "#6599C0",
  },
  inactive: {
    main: "#F0FBFF",
  },
  darkGray: {
    main: "rgb(104, 125, 143)",
  },
  lightGray: {
    main: "#c7cfd4",
  },
  warning: {
    main: "rgb(209, 153, 20)",
  },
  error: {
    main: "rgb(216, 54, 76)",
  },
  success: {
    main: "rgb(103, 212, 93)",
  },
  card: {
    main: "#f7ffff",
  },
};
export const typography = {
  fontFamily: [].join(","),
  button: {
    fontSize: "14px",
  },

  h1: {
    fontSize: "64px",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "48px",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "40px",
    fontWeight: "bold",
  },
  h4: {
    fontSize: 26,
    fontWeight: "bold",
  },
  h5: {
    fontSize: 23,
    fontWeight: "bold",
  },
  h6: {
    fontSize: 20,
  },

  subtitle1: {
    fontSize: "0.955rem",
  },
  subtitle2: {
    fontSize: 12,
  },

  body1: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  body2: {
    fontSize: "14px",
  },
  caption: {
    fontSize: "12px",
  },
  inputTitle: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: colors.text.main
  },
};
export const lightThemeOptions = {
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: "primary",
          },
          style: {
            backgroundColor: colors.background.main,
            color: colors.primary.main,
            boxShadow: "rgb(32 101 209 / 24%) 0px 10px 16px 0px",
            "&:hover": {
              backgroundColor: colors.background.hover,
              boxShadow: "none",
            },
          },
        },
        {
          props: {
            variant: "error",
          },
          style: {
            backgroundColor: colors.background.main,
            color: colors.primary.main,
            "&:hover": {
              backgroundColor: colors.background.paper,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            transform: "scale(1.1)",
            transition: "150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          "&.MuiTableHead-root ": {
            backgroundColor: colors.inactive.main,
          },
          ".MuiTableCell-root.MuiTableCell-head": {
            color: colors.darkGray.main,
            textTransform: "capitalize",
          },
          ".MuiTableCell-root.MuiTableCell-head:first-of-type": {
            borderRadius: "10px 0 0 10px",
          },
          ".MuiTableCell-root.MuiTableCell-head:last-of-type": {
            borderRadius: "0 10px 10px 0",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root.MuiTableCell-body": {
            color: colors.text.main,
            borderBottom: "none",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkGray.main,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            color: colors.primary.main,
            marginLeft: "0",
          },
          "&.MuiFormHelperText-root.Mui-error": {
            color: colors.error.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.text.secondary,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          textAlign: "center",
          borderBottom: `1px solid ${divider}`,
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background.hover,
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiDialog-container .MuiPaper-root": {
            backgroundColor: colors.background.main,
            borderRadius: 8,
            width: "35%", // this one will be deleted in the end
            zIndex: "1200",
          },
        },
      },
    },
  },

  palette: {
    mode: "light",
    ...colors,
  },
  typography: {
    ...typography,
  },
};
