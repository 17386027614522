import { useQuery } from "react-query";
import { _Company } from "api/company/company";

export const useActiveCompany = (id) => {
  const { data, isLoading, refetch } = useQuery(["company"], () =>
    _Company.getActive().then((res) => res)
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
