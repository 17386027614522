// admin dash
import DiscountIcon from "@mui/icons-material/Discount";
import { Box, IconButton, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loader from "components/shared/Loader";
import { useStatistics } from "hooks/statistics/useStatistics";
import { useTranslation } from "react-i18next";
const DashboardIndex = () => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useStatistics();
  const role = localStorage.getItem("role");
  return (
    <>
      {isLoading && <Loader />}
      {role === "admin" ? (
        <Box
          component="main"
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("client count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.client_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("companies count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.companies_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("passengers count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.passengers_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("buses count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.buses_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("client count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.client_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("passengers count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.passengers_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              boxShadow: "0px 0px 20px #80808030",
              p: 2,
              background: "#80808030",
              borderRadius: "10px",
              width: "20%",
              height: "20%",
              color: "text.main",
              display: "flex",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <Box>
              <IconButton>
                <DiscountIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ color: "darkGray.main", fontSize: "25px" }}>
                {t("trips count")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "60px",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data?.statistic?.trips_count ?? "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 5,
          width: "100%",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          <Typography sx={{ color: "text.main" }}>
            {t("top clients")}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">{t("first name")}</TableCell>
                  <TableCell align="right">{t("last name")}</TableCell>
                  <TableCell align="right">{t("father name")}</TableCell>
                  <TableCell align="right">{t("email")}</TableCell>
                  <TableCell align="right">{t("phone")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.statistic.top_clients?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="right">
                      {row.first_name ?? "N/A"}
                    </TableCell>
                    <TableCell align="right">
                      {row.last_name ?? "N/A"}
                    </TableCell>
                    <TableCell align="right">
                      {row.father_name ?? "N/A"}
                    </TableCell>
                    <TableCell align="right">{row.email ?? "N/A"}</TableCell>
                    <TableCell align="right">{row.phone ?? "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {role === "admin" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <Typography sx={{ color: "text.main" }}>
              {t("top companies")}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 150 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">{t("name")}</TableCell>
                    <TableCell align="right">{t("image")}</TableCell>
                    <TableCell align="right">{t("phone")}</TableCell>
                    <TableCell align="right">{t("email")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.statistic.top_companies?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="right">{row.name ?? "N/A"}</TableCell>
                      <TableCell align="right">
                        <img src={row.image} alt="" width={50} height={50} />
                      </TableCell>
                      <TableCell align="right">{row.email ?? "N/A"}</TableCell>
                      <TableCell align="right">{row.phone ?? "N/A"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DashboardIndex;
