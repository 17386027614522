import { Typography, Box, Button, Grid } from "@mui/material";
import React from "react";
import Loader from "components/shared/Loader";
import { useBookingCreate } from "../hooks/useBookingCreate";
import InputRepeater from "../components/InputRepeater";
import ButtonLoader from "components/shared/ButtonLoader";

const BookingCreate = () => {
  const {
    handleCancel,
    hanldeCreate,
    register,
    handleSubmit,
    loading,
    t,
    errors,
    setValue,
    control,
    watch
  } = useBookingCreate()

  return (
    <Box>
      {loading && <Loader />}
      <Typography sx={{ color: "text.main", mb: "16px" }} variant="h5">
        {t("Create Booking")}
      </Typography>

      <Grid container spacing={2} component="form">
        <InputRepeater
          control={control}
          errors={errors}
          register={register}
          setPassengers={setValue}
          watch={watch}
        />
      </Grid>
      <Box
        sx={{
          mt: "20px",
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "15px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "120px",
            borderColor: "origin.main",
            color: "primary.main",
            "&:hover": {
              borderColor: "origin.main",
            },
          }}
          onClick={handleCancel}
        >
          {t("Cancel")}
        </Button>
        <ButtonLoader name={t("Submit")}
          onClick={() => handleSubmit(hanldeCreate)()}
          type="submit"
          loading={loading}
          disableOnLoading
        >
          {t("Submit")}
        </ButtonLoader>
      </Box>
    </Box>
  );
};

export default BookingCreate;
