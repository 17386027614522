import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ButtonSideBarStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  backgroundColor: "inherit",
  textDecoration: "none",
  minWidth: "20px",
  color: theme.palette.text?.main,
  display: "flex",
  justifyContent: "start",
  padding: "10px 15px 10px 15px",
  borderRadius: "5px",
  alignItems: "center",
  minHeight: 48,
  columnGap: "10px",
  "&:hover": {
    backgroundColor: theme.palette.background.hover,
  },
  "& .MuiSvgIcon-root": {
    transition: "250ms",
  },
  ...(active && {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.origin.main,
  }),
}));
