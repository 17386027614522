
import { useState } from "react";
import { useQuery } from "react-query";
import { _Booking } from "api/booking/booking";

export const useBooking = (id) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [query, setQuery] = useState("");

  const { data, isLoading, refetch } = useQuery(
    ["booking", page, count, query],
    () =>
      _Booking
        .index({
          query,
          page,
          count,
          id
        })
        .then((res) => res),
    {
      refetchInterval: 6000,
    }
  );

  return {
    data,
    isLoading,
    page,
    setPage,
    count,
    setCount,
    refetch,
    setQuery,
  }
};
