import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";

export const _Employee = {
  index: async ({ query, page, limit = 9 }) => {
    return _axios
      .get(`/company/employee?page=${page}&count=${limit}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data);
  },
  post: (data, setLoading, navigate) =>
    _axios.post("/company/employee", data).then((res) => {
      setLoading(true);
      navigate(-1);
    }),

  view: async (id) => {
    return _axios
      .get(`/company/employee/${id}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data);
  },
  delete: (id) =>
    _axios.get(`/company/employee/delete/${id}`).then((res) => res.data),

  update: ({ editedID, formData, loading, setLoading, setOpen }) =>
    _axios.post(`/company/employee/${editedID}`, formData).then((res) => {
      setLoading(false);
      setOpen(false);
    }),

  changePass: (data, id) => {
    return _axios
      .post(`/company/employee/update-password/${id}`, data)
      .then((res) => res.data);
  },
};
