import {
  Typography,
  Box,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { BoxStyled } from "components/styled/BoxStyled";
import React, { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { colorStore } from "store/ColorsStore";

import { useTrip } from "hooks/trip/useTrip";
import TripUpdate from "./TripUpdate";
import DeleteDialog from "../components/Dialog";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useDebounce } from "hooks/useDebounce";
import { TextFieldStyled } from "components/styled/TextField";
import StopBooking from "../components/StopBooking";

const TripIndex = () => {
  const { t } = useTranslation("index");

  const {
    data,
    page,
    setPage,
    isLoading,
    count,
    setQuery,
    setSearchTo,
    setCompanySearch,
  } = useTrip();

  const navigate = useNavigate();
  const [direction] = settingsStore((state) => [state.direction]);

  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const role = localStorage.getItem("role");

  const handleSearch = useDebounce((e) => {
    setQuery(e.target.value);
  }, 1000);
  const handleSearchTo = useDebounce((e) => {
    setSearchTo(e.target.value);
  }, 1000);
  const handleCompanySearch = useDebounce((e) => {
    setCompanySearch(e.target.value);
  }, 1000);

  const columns = useMemo(() => {
    return role === "admin"
      ? [
          t("driver name"),
          t("type"),
          t("booking in office"),
          t("trip number"),
          t("is ended"),
          t("bus number"),
          t("company"),
          t("from"),
          t("to"),
          t("available seats"),
          t("pending booking count"),
          t("operations"),
        ]
      : [
          t("driver name"),
          t("type"),
          t("booking in office"),
          t("trip number"),
          t("is ended"),
          t("bus number"),
          t("from"),
          t("to"),
          t("available seats"),
          t("pending booking count"),
          t("operations"),
        ];
  }, [t, role]);

  const theme = useTheme();

  const handleView = useCallback(
    (id) => {
      navigate(`view/${id}`);
    },
    [navigate]
  );
  const handleEdit = useCallback(
    (id) => {
      setEditedID(id);
    },
    [setEditedID]
  );

  const rows = useMemo(() => {
    return data?.trips?.map((trip, index) => (
      <TableRow sx={{ height: "65px" }} key={trip.id} hover>
        <TableCell sx={{ minWidth: 100 }}>
          {trip?.driver_name ?? "🚫"}
        </TableCell>
        <TableCell sx={{ width: 20 }}>
          {trip?.type === "normal" ? (
            <Chip label={trip?.type} color="primary" variant="outlined" />
          ) : (
            <Chip label={trip?.type} color="info" variant="outlined" />
          )}
        </TableCell>
        <TableCell sx={{ width: 20 }}>
          {trip?.booking_in_office ? (
            <Chip label={"yes"} color="primary" variant="outlined" />
          ) : (
            <Chip label={"no"} color="info" variant="outlined" />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 100 }}>
          {trip?.trip_number ?? "🚫"}
        </TableCell>
        <TableCell sx={{ width: 20 }}>
          {trip?.is_end ? (
            <Chip label={"yes"} color="primary" variant="outlined" />
          ) : (
            <Chip label={"no"} color="info" variant="outlined" />
          )}
        </TableCell>
        <TableCell sx={{ width: 20 }}>{trip?.bus_number ?? "🚫"}</TableCell>
        {role === "admin" && (
          <TableCell sx={{ minWidth: 100 }}>
            {trip?.company_name ?? "🚫"}
          </TableCell>
        )}
        <TableCell sx={{ minWidth: 100 }}>{trip?.from ?? "🚫"}</TableCell>
        <TableCell sx={{ minWidth: 100 }}>{trip?.to ?? "🚫"}</TableCell>
        <TableCell sx={{ width: 20 }}>
          {trip?.available_seats ?? "🚫"}
        </TableCell>
        <TableCell sx={{ width: 20 }}>
          {trip?.pending_booking_count === 0
            ? t("no booking")
            : trip?.pending_booking_count ?? "🚫"}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            minWidth: 200,
          }}
        >
          {/* //* تعديل */}
          <IconButton onClick={() => handleEdit(trip?.id)}>
            <Tooltip title={direction === "ltr" ? "Edit" : "تعديل"}>
              <lord-icon
                src="https://cdn.lordicon.com/wloilxuq.json"
                trigger="morph"
                colors={`primary:${theme.palette.text.main}`}
                style={{ width: 20, height: 20 }}
              ></lord-icon>
            </Tooltip>
          </IconButton>
          {/*//* حذف */}
          <IconButton>
            <Tooltip title={direction === "ltr" ? "Delete" : "حذف"}>
              <DeleteDialog id={trip?.id} count={count} page={page} />
            </Tooltip>
          </IconButton>
          {/* Stopped */}
          {localStorage.getItem("role") !== "employee" ? (
            !trip?.is_end ? (
              <IconButton>
                <Tooltip title={direction === "ltr" ? "Delete" : "حذف"}>
                  <StopBooking id={trip?.id} count={count} page={page} />
                </Tooltip>
              </IconButton>
            ) : null
          ) : null}
          {/*//* مشاهدة */}
          <IconButton onClick={() => handleView(trip.id)}>
            <Tooltip title={direction === "ltr" ? "View" : "مشاهدة"}>
              <lord-icon
                src="https://cdn.lordicon.com/fcrbbplx.json"
                trigger="morph"
                colors={`primary:${theme.palette.primary.main}`}
                style={{ width: 20, height: 20 }}
              ></lord-icon>
            </Tooltip>
          </IconButton>
          {/* //*View booking */}
          {/* <IconButton sx={{ width: "40px", height: "40px" }}>
            <Tooltip
              title={
                direction === "ltr" ? "pending booking count" : "عرض الحجز"
              }
            >
              <Typography>{trip.pending_booking_count}</Typography>
            </Tooltip>
          </IconButton> */}
          <IconButton sx={{ width: "40px", height: "40px" }}>
            <Link to={`/dashboard/${role}/booking/${trip.id}`}>
              <Tooltip
                title={direction === "ltr" ? "View booking" : "عرض الحجز"}
              >
                {/* <Typography>{trip.pending_booking_count}</Typography> */}
                <lord-icon
                  src="https://cdn.lordicon.com/qtxqkhzr.json"
                  trigger="morph"
                  colors={`primary:${theme.palette.text.main}`}
                  style={{ width: 20, height: 20 }}
                ></lord-icon>
              </Tooltip>
            </Link>
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [
    data,
    count,
    direction,
    handleEdit,
    handleView,
    page,
    theme.palette.primary.main,
    theme.palette.text.main,
    role,
  ]);

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <>
      {isLoading && <Loader />}
      {editedID && <TripUpdate id={editedID} />}

      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("trips")}
          </Typography>

          <Button
            startIcon={<AddIcon />}
            sx={{
              color: "primary.main",
              backgroundColor: "origin.main",
              "&:hover": { backgroundColor: "origin.main" },
            }}
            onClick={handleCreate}
          >
            {t("New trip")}
          </Button>
        </Box>

        <BoxStyled sx={{ px: "10px" }}>
          <TextFieldStyled
            sx={{ width: "25%", m: "10px" }}
            placeholder={t("from")}
            onChange={handleSearch}
          />
          <TextFieldStyled
            sx={{ width: "25%", m: "10px" }}
            placeholder={t("to")}
            onChange={handleSearchTo}
          />
          {role === "admin" && (
            <TextFieldStyled
              sx={{ width: "25%", m: "10px" }}
              placeholder={t("Company")}
              onChange={handleCompanySearch}
            />
          )}

          <Table
            sx={{ marginTop: "20px" }}
            columns={columns}
            rows={rows}
            page={page}
            setPage={setPage}
            count={Math.ceil(data?.pagination?.total / count)}
          />
        </BoxStyled>
      </Box>
    </>
  );
};

export default TripIndex;
