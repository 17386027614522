
import { React, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { colorStore } from "store/ColorsStore";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextFieldStyled } from "components/styled/TextField";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { _Bus } from "api/bus/bus";
import Loader from "components/shared/Loader";
import ButtonLoader from "components/shared/ButtonLoader";
import { useActiveCompany } from "hooks/company/useActiveCompany";

const BusUpdate = ({ id }) => {
  const { data: companyData } = useActiveCompany();
  const role = localStorage.getItem("role")
  const { t } = useTranslation("index");

  let schema = yup.object().shape({
    number: yup.number().required(t("This field is required")).typeError(t("This field is required")),
    seats_count: yup.number().required(t("This field is required")).typeError(t("This field is required")),
  })

  const [editedID, setEditedID] = colorStore((state) => [
    state.editedID,
    state.setEditedID,
  ])

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    _Bus.view(editedID).then((res) => {
      setData(res?.bus);
    });
  }, [id, editedID]);

  const details = [
    { head: t("number"), type: "number", placeholder: t("number"), name: "number", register: "number", error: "number", helperText: "number", defaultValue: data?.number, },
    { head: t("seats count"), type: "number", placeholder: t("seats_count"), name: "seats_count", register: "seats_count", error: "seats_count", helperText: "seats_count", defaultValue: data?.seats_count, },
  ]

  const handleClose = () => {
    setOpen(false);
    setEditedID(null);
  };

  const { mutate } = useMutation((data) => createPost(data))

  async function createPost(data) {
    _Bus.update({
      editedID: editedID,
      formData: data,
    }).catch(err => {
      setLoading(false)
    }).then((res) => {
      setLoading(false)
      if (res.status === 200) {
        handleClose()
      }
    })
  }

  const hanldeUpdate = (input) => {
    const formData = new FormData()
    formData.append("number", input.number);
    formData.append("seats_count", input.seats_count);
    formData.append("company_id", input.company_id);
    formData.append("_method", 'put');

    mutate(formData);
    setLoading(true);
  }

  return (
    <>
      {loading && <Loader />}
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle sx={{ color: "primary.main" }}>{t("Edit Row")}</DialogTitle>
        {!!data ? (
          <>
            <Grid container component="form" key={id}>
              {details?.map((item, index) => (
                <Grid key={index} item md={6} sx={{ p: "10px" }}>
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{item.head}</Typography>
                  </Box>
                  <TextFieldStyled
                    sx={{ width: "100%" }}
                    type={item.type}
                    placeholder={item.placeholder}
                    defaultValue={item.defaultValue}
                    name={item.name}
                    {...register(item.register)}
                    error={errors[item.error]?.message}
                    helperText={errors[item.helperText]?.message || ""}
                  />
                </Grid>
              ))}
              {role === 'admin' &&
                <Grid item md={6} sx={{ p: "10px" }}>
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{t("company")}</Typography>
                  </Box>
                  <Select label={t("company")}
                    sx={{ mb: '10px', width: '100%' }}
                    name="company"
                    id="company"
                    {...register('company_id')}
                    error={errors?.company_id?.message}
                    helperText={errors?.company_id?.message || ""}
                    defaultValue={data?.company_id}
                  >
                    {companyData?.companies?.map((item) =>
                      <MenuItem key={item} value={item?.id}>{item?.name}</MenuItem>
                    )}
                  </Select>
                </Grid>}
            </Grid>
          </>

        ) : 'Loading...'}

        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "primary.main" }}>
            {t("Cancel")}
          </Button>
          {loading && <Loader />}

          <ButtonLoader name={t("Submit")}
            onClick={() => handleSubmit(hanldeUpdate)()}
            type="save"
            loading={loading}
            disableOnLoading
          >
            {t("Submit")}
          </ButtonLoader>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default BusUpdate;

