import { useState } from "react";
import { useQuery } from "react-query";
import { _Employee } from "api/CompanyApi/employee";

export const useEmployee = (id) => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [query, setQuery] = useState("");

    const { data, isLoading, refetch } = useQuery(
        ["employee", page, count, query],
        () =>
            _Employee
                .index({
                    query,
                    page,
                    count,
                })
                .then((res) => res)
    );

    return {
        data,
        isLoading,
        page,
        setPage,
        count,
        setCount,
        refetch,
        setQuery,
    };
};
