
import React from "react";
import { Route, Routes } from "react-router-dom";
import CustomUsersNotifiComponent from "./CustomUsersNotifiComponent";
import CustomUsersNotifiCreate from "./pages/CustomUsersNotifiCreate";

const CustomUsersNotifiRouting = () => {
    return (
        <Routes>
            <Route element={<CustomUsersNotifiComponent />}>
                <Route path="/create" element={<CustomUsersNotifiCreate />} />
            </Route>
            <Route path="*" element={<p>not found 404</p>} />
        </Routes>
    );
};

export default CustomUsersNotifiRouting;
