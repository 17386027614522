import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "../../interceptor/http-config";
export const _Review = {
  index: async () => {
    const role = localStorage.getItem("role");
    return _axios
      .get(`/${role}/reviews`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data);
  },

  post: (data, setLoading, navigate) =>
    _axios.post("/company/reviews", data).then((res) => {
      setLoading(true);
      navigate(-1);
    }),
  delete: (id) =>
    _axios.delete("/company/reviews/" + id).then((res) => res.data),

  update: ({ editedID, formData, loading, setLoading, setOpen }) =>
    _axios.post("/company/reviews/" + editedID, formData).then((res) => {
      setLoading(false);
      setOpen(false);
    }),
};
