
import { useState } from "react";
import { useQuery } from "react-query";
import { _Bus } from "api/bus/bus";

export const useBus = (id) => {

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [query, setQuery] = useState("");
  const [searchTo, setSearchTo] = useState(1);

  const { data, isLoading, refetch } = useQuery(
    ["bus", page, count, query, searchTo],
    () =>
      _Bus
        .index({
          query,
          page,
          count,
          searchTo
        })
        .then((res) => res)
  );

  return {
    data,
    isLoading,
    page,
    setPage,
    count,
    setCount,
    refetch,
    setQuery,
    searchTo,
    setSearchTo
  };
};
