
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _Employee } from "api/CompanyApi/employee";

let schema = yup.object().shape({
  full_name: yup.string().trim().required("full name is required"),
  email: yup.string().trim().required("email is required"),
  phone: yup.string().trim().required("phone is required"),
  address: yup.string().trim().required("address is required"),
  password: yup
    .string()
    .trim().required("Password is required")
    .min(6, "The Password must be of eight characters")
    .max(20, "The Password must be of 20 characters"),
  password_confirmation: yup
    .string()
    .trim().required("Confirm password is required")
    .min(6, "The confirm password must be of eight characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match")
})

export const useEmployeeCreate = () => {
  const { t } = useTranslation("index")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, setValue, control } = useForm(formOptions)
  const { errors } = formState
  const { mutate } = useMutation((data) => createPost(data))
  const [details, setDetails] = useState([])

  async function createPost(data) {
    _Employee
      .post(data, setLoading)
      .then(setLoading(true))
      .finally(() => {
        setLoading(false)
        navigate(-1)
      })
  }

  const handleCancel = () => navigate(-1)

  const handleReset = () => {
    const form = document.querySelector('form');
    if (form) form.reset()
  }

  const hanldeCreate = (input) => {
    const formData = new FormData()
    const inputWithoutBirthday = { ...input };
    delete inputWithoutBirthday.birthday;
    for (const [key, value] of Object.entries(inputWithoutBirthday)) {
      formData.append(key, value);
    }
    mutate(formData);
    setLoading(true);
  }

  useEffect(() => {
    const fields = [
      ["full_name", "text"],
      ["email", "text"],
      ["phone", "text"],
      ["address", "text"],
      ["password", "password"],
      ["password_confirmation", "password"],
    ]
    const data = []
    fields.map(item => {
      const key = item[0]
      const type = item[1];
      var element = {
        head: t(key).replace('_', ' '),
        type: type,
        placeholder: t(key).replace('_', ' '),
        name: key,
        register: key,
        error: key,
        helperText: key
      }
      data.push(element)
      return element
    })
    setDetails(data)
  }, [t])

  return {
    handleCancel,
    handleReset,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    control
  };
};

