import { _axios } from "../../interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
const role = localStorage.getItem("role");

export const _Profile = {
  index: async ({ query, page, limit = 10, role }) => {
    return _axios
      .get(
        `/${
          role === "admin" ? "admin" : "company"
        }/profile?page=${page}&count=${limit}${
          query !== "" ? `&search=${query}` : ""
        }`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },

  update: ({ formData, role }) =>
    _axios
      .post(
        `/${role === "admin" ? "admin" : "company"}/profile/edit-profile`,
        formData
      )
      .then((res) => res.data),

  changePass: async (data) => {
    return _axios
      .post(
        `${role === "admin" ? "admin" : "company"}/profile/change-password`,
        data
      )
      .then((res) => res.data);
  },
};
