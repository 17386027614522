import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import {
  Box,
  IconButton,
  Rating,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "components/shared";
import Loader from "components/shared/Loader";
import { useReview } from "hooks/review/useReview";
import { useTranslation } from "react-i18next";
import { colorStore } from "store/ColorsStore";
import { settingsStore } from "store/settingsStore";
import ReviewUpdate from "./ReviewUpdate";

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import { _AuthApi } from "api/auth";

const ReviewIndex = () => {
  const { t } = useTranslation("index");
  const { data, page, setPage, isLoading, count } = useReview();

  const navigate = useNavigate();
  const [direction] = settingsStore((state) => [state.direction]);

  const [editedID] = colorStore((state) => [state.editedID]);
  const role = localStorage.getItem("role");
  const columns = useMemo(() => {
    return role === "admin"
      ? [
          t("client name"),
          t("trip number"),
          t("client phone"),
          t("company name"),
          t("rating"),
          // t("review"),
          t("operations"),
        ]
      : [
          t("client name"),
          t("trip number"),
          t("client phone"),
          t("rating"),
          // t("review"),
          t("operations"),
        ];
  }, [t, role]);

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const handleView = useCallback(
    (id) => {
      navigate("view/" + id);
    },
    [navigate]
  );

  const rows = useMemo(() => {
    const customIcons = {
      1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: "Very Dissatisfied",
      },
      2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: "Dissatisfied",
      },
      3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: "Neutral",
      },
      4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: "Satisfied",
      },
      5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: "Very Satisfied",
      },
    };

    function IconContainer(props) {
      const { value, ...other } = props;
      return <span {...other}>{customIcons[value].icon}</span>;
    }
    return data?.reviews?.map((review, id) => (
      <TableRow sx={{ height: "65px" }} key={review.id} hover>
        <TableCell sx={{ minWidth: 50 }}>
          {review?.client_name ?? "N/A"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {review?.trip_number ?? "N/A"}
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {review?.client_phone ?? "N/A"}
        </TableCell>
        {role === "admin" && (
          <TableCell sx={{ minWidth: 50 }}>
            {review?.company_name ?? "N/A"}
          </TableCell>
        )}
        <TableCell sx={{ minWidth: 50 }}>
          {(
            <StyledRating
              name="highlight-selected-only"
              defaultValue={review?.rate}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value]?.label}
              readOnly
              highlightSelectedOnly
            />
          ) ?? "N/A"}
        </TableCell>
        {/* <TableCell sx={{ minWidth: 200, maxWidth: 300 }}>
          {review?.review ?? "N/A"}
        </TableCell> */}

        <TableCell
          align="center"
          sx={{
            minWidth: 200,
          }}
        >
          <IconButton onClick={() => handleView(review.id)}>
            <Tooltip title={direction === "ltr" ? "View" : "مشاهدة"}>
              <VisibilityTwoToneIcon color="primary" />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() =>
              navigate(`/dashboard/${role}/trip/view/${review.trip_id}`)
            }
          >
            <Tooltip title={direction === "ltr" ? "View trip" : "عرض الرحلة"}>
              <lord-icon
                src="https://cdn.lordicon.com/ofwpzftr.json"
                trigger="hover"
                colors="primary:#121331"
                style={{ width: 25, height: 25 }}
              ></lord-icon>
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [data, direction, handleView, navigate, role]);

  return (
    <>
      {isLoading && <Loader />}
      {editedID && <ReviewUpdate id={editedID} />}

      <Box
        sx={{
          width: { sl: "300px" },
          backgroundColor: { xs: "background.main" },
          ml: { xs: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "25px",
          }}
        >
          <Typography sx={{ color: "text.main" }} variant="h5">
            {t("review")}
          </Typography>
        </Box>

        <BoxStyled sx={{ px: "10px" }}>
          <Table
            columns={columns}
            rows={rows}
            page={page}
            setPage={setPage}
            count={Math.ceil(data?.pagination?.total / count)}
          />
        </BoxStyled>
      </Box>
    </>
  );
};

export default ReviewIndex;
