
import React from "react";
import { Route, Routes } from "react-router-dom";
import ReviewComponent from "./ReviewComponent";
import ReviewIndex from "./pages/ReviewIndex";
import ReviewUpdate from "./pages/ReviewUpdate";
import ReviewView from "./pages/ReviewView";
import ReviewCreate from "./pages/ReviewCreate";

const ReviewRouting = () => {
  return (
    <Routes>
      <Route element={<ReviewComponent />}>
        <Route path="/" element={<ReviewIndex />} />
        <Route path="/update/:id" element={<ReviewUpdate />} />
        <Route path="/view/:id" element={<ReviewView />} />
        <Route path="/create" element={<ReviewCreate />} />
      </Route>
      <Route path="*" element={<p>not found 404</p>} />
    </Routes>
  );
};

export default ReviewRouting;
